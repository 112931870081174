// import * as types from "../../constants";
import LoginAction from "../actions/authActions";

export default function User(
  state = {
    User: "USER_ONE",
    data: [],
    businessTablePageNumber: 1,
    shiftTablePageNumber: 1,
    BoroughTablePageNumber: 1,
    PositionTablePageNumber: 1,
    VenueTypeTablePageNumber: 1,
    ShifterTablePageNumber: 1,
    CuisineTypeTablePageNumber: 1,
    PaymentDetailTablePageNumber: 1,
    BillingTablePageNumber: 1,
  },
  action
) {
  switch (action.type) {
    case LoginAction.GET_LOGIN:
      return {
        ...state,
        data: action.data,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.GET_LOGOUT:
      return {
        ...state,
        data: undefined,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.BUSINESS_TABLE_PAGENUMBER:
      return {
        ...state,
        businessTablePageNumber: action.data,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.SHIFT_TABLE_PAGENUMBER:
      return {
        ...state,
        shiftTablePageNumber: action.data,
        BoroughTablePageNumber: 1,
        VenueTypeTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };

    case LoginAction.BOROUGH_TABLE:
      return {
        ...state,
        BoroughTablePageNumber: action.data,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        VenueTypeTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.POSITION_TABLE:
      return {
        ...state,
        PositionTablePageNumber: action.data,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        VenueTypeTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.VENUETYPE_TABLE:
      return {
        ...state,
        PositionTablePageNumber: 1,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        CuisineTypeTablePageNumber: 1,
        VenueTypeTablePageNumber: action.data,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.SHIFTER_TABLE:
      return {
        ...state,
        PositionTablePageNumber: 1,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        ShifterTablePageNumber: action.data,
        CuisineTypeTablePageNumber: 1,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.CUISINE_TABLE:
      return {
        ...state,
        PositionTablePageNumber: 1,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        CuisineTypeTablePageNumber: action.data,
        BillingTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
      };
    case LoginAction.PAYMENTDETAIL_TABLE:
      return {
        ...state,
        PositionTablePageNumber: 1,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        PaymentDetailTablePageNumber: action.data,
        BillingTablePageNumber: 1,
      };
    case LoginAction.BILLING_TABLE:
      return {
        ...state,
        PositionTablePageNumber: 1,
        businessTablePageNumber: 1,
        shiftTablePageNumber: 1,
        BoroughTablePageNumber: 1,
        PaymentDetailTablePageNumber: 1,
        BillingTablePageNumber: action.data,
      };
    default:
      return state;
  }
}
