import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post } from "../../../utils/api";
import { useSelector } from "react-redux";
import PaymentDetailsTable from "./PaymentDetailsTable";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

function PaymentDetails() {
  const history = useHistory();

  const redux = useSelector((data) => {
    return data;
  });
  const [TableData, setTableData] = useState([
    {
      id: "1",
      name: "Maheen",
      applied: "434434",
      status: "enable",
    },
  ]);
  const [limit, setLimit] = useState(5);
  const [PageNumber, setPageNumber] = useState(
    redux?.authReducer?.PaymentDetailTablePageNumber
  );
  const [TotalRows, setTotalRows] = useState(1);
  const [TableColumn, setTableColumn] = useState([
    "S.NO",
    "Shifter Name",
    "Applied Shifts",
    "Status",
    "View",
  ]);
  const [AddModal, setAddModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [Name, setName] = useState("");
  const [Status, setStatus] = useState("enable");
  const [PaymentDetailsId, setPaymentDetailsId] = useState("");
  useEffect(() => {
    getPaymentDetails();
  }, [PageNumber, limit]);

  const getPaymentDetails = async () => {
    try {
      const body = {
        pageNumber: PageNumber,
        limit: limit,
        status: "enable",
      };
      const getPaymentDetailsRes = await post(API.PaymentDetails, body);
      // console.log("getPaymentDetailsRes", getPaymentDetailsRes);
      //   setTableData(getPaymentDetailsRes?.data);
      //   setTotalRows(getPaymentDetailsRes?.count);
    } catch (error) {
      // console.log("error", error);
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      {/* <ArrowBack
        style={{ marginBottom: "20px", cursor: "pointer" }}
        onClick={() => history.push("/setting")}
      /> */}
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Shifterr Payment Details
          </Typography>
        </Grid>
        <Grid item>
          {/* <Button
            style={{
              backgroundColor: "rgb(115, 29, 216)",
              padding: "10px",
              color: "white",
              borderRadius: "5px",
            }}
            onClick={() => setAddModal(true)}
          >
            Add Paymen tDetails
          </Button> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "31px" }}
        >
          <PaymentDetailsTable
            EditModal={EditModal}
            setEditModal={setEditModal}
            DeleteModal={DeleteModal}
            setDeleteModal={setDeleteModal}
            TableData={TableData}
            TableColumn={TableColumn}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            PaymentDetailsId={PaymentDetailsId}
            setPaymentDetailsId={setPaymentDetailsId}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PaginationComponent
            limit={limit}
            setLimit={setLimit}
            pageNumber={PageNumber}
            setPageNumber={setPageNumber}
            totalRows={TotalRows}
            from="PaymentTable"
          />
        </Grid>
        {/* {AddModal && (
          <AddPaymentDetailsModal
            AddModal={AddModal}
            setAddModal={setAddModal}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            getPaymentDetails={getPaymentDetails}
          />
        )}
        {EditModal && (
          <EditPaymentDetailsModal
            EditModal={EditModal}
            setEditModal={setEditModal}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            getPaymentDetails={getPaymentDetails}
            PaymentDetailsId={PaymentDetailsId}
            setPaymentDetailsId={setPaymentDetailsId}
          />
        )} */}
        {/* {DeleteModal && (
          <DeletePaymentDetailsModal
            DeleteModal={DeleteModal}
            setDeleteModal={setDeleteModal}
            PaymentDetailsId={PaymentDetailsId}
            setStatus={setStatus}
            getPaymentDetails={getPaymentDetails}
          />
        )} */}
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(PaymentDetails);
