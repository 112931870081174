import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import ApproveModal from "./ApproveModal";
import UserProfileModal from "./UserProfileModal";

const MainGridBody = styled(Box)`
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  // background-color: red;

  ${(props) => props.theme.breakpoints.down("sm")} {
    border: 1px solid rgba(207, 207, 207, 1);
    border-radius: 15px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    // background-color: red;
  }
`;

function BusinessProfile({}) {
  const location = useLocation();
  const history = useHistory();
  const [approveModal, setapproveModal] = useState(false);
  const [businessData, setBusinessData] = useState(location.state);
  const [ProfileModal, setProfileModal] = useState(false);
  console.log("businessData", businessData);

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() => history.push("/dashboard/business")}
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            Business Profile
          </Typography>
        </Grid>

        {businessData.status === "pending" ? (
          <Grid item>
            <Button
              onClick={() => setapproveModal(true)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Approve Business
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              onClick={() => setProfileModal(true)}
              src={
                businessData?.businessDetail?.profileImage
                  ? businessData?.businessDetail?.profileImage
                  : "https://sbcf.fr/wp-content/uploads/2018/03/sbcf-default-avatar.png"
              }
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "27px",
                width: "90px",
                height: "90px",
                borderRadius: "100px",
                cursor: "pointer",
              }}
            />
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                BASIC DETAILS
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Venue Name
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {businessData?.businessDetail?.venueName
                    ? businessData?.businessDetail?.venueName
                    : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Address
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {businessData?.address ? businessData?.address : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Phone Number
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    window.open(`tel:${businessData.phone}`);
                    e.preventDefault();
                  }}
                >
                  {businessData?.phone ? businessData?.phone : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Website
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {businessData?.businessDetail?.website
                    ? businessData?.businessDetail?.website
                    : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Venue Ambiance Description
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {businessData?.businessDetail?.venueAmbianceDescription
                    ? businessData?.businessDetail?.venueAmbianceDescription
                    : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Vaccination Required
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: businessData?.businessDetail
                        ?.venueAmbianceDescription
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{
                      backgroundColor: !businessData?.businessDetail
                        ?.venueAmbianceDescription
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    No
                  </Button>
                </p>
              </p>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                CONTACT DETAILS
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Primary Contact Email
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {businessData?.businessDetail?.primaryContactEmail
                    ? businessData?.businessDetail?.primaryContactEmail
                    : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Primary Contact Phone Number
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {businessData?.businessDetail?.primaryContactNumber
                    ? businessData?.businessDetail?.primaryContactNumber
                    : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Address
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {businessData?.address ? businessData?.address : "--"}
                </p>
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MainGridBody item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  padding: "19px",
                  marginTop: "20px",
                }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  PERSONAL DETAILS
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Name
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.firstName
                      ? businessData?.firstName + businessData?.lastName
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Email
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      window.location.href = `mailto:${businessData.email}`;
                      e.preventDefault();
                    }}
                  >
                    {businessData.email ? businessData.email : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Phone Number
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      window.open(`tel:${businessData.phone}`);
                      e.preventDefault();
                    }}
                  >
                    {businessData.phone ? businessData.phone : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Zipcode
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData.zipCode ? businessData.zipCode : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Website
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.businessDetail?.website
                      ? businessData?.businessDetail?.website
                      : "--"}
                  </p>
                </p>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: "19px", marginTop: "20px" }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  VENUE TYPE
                </p>

                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {businessData?.businessDetail?.venueType?.name
                    ? businessData?.businessDetail?.venueType?.name
                    : "--"}
                </p>

                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  OTHER INFORMATION
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    {businessData?.businessDetail?.otherInformation
                      ? businessData?.businessDetail?.otherInformation
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  YOUR POLICY
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Uniform Policy
                    <p
                      style={{
                        color: "rgba(33, 33, 33, 1)",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {businessData?.businessDetail?.uniformPolicy
                        ? businessData?.businessDetail?.uniformPolicy
                        : "--"}
                    </p>
                  </p>
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Tips Policy
                    <p
                      style={{
                        color: "rgba(33, 33, 33, 1)",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      {businessData?.businessDetail?.tipsPolicy
                        ? businessData?.businessDetail?.tipsPolicy
                        : "--"}
                    </p>
                  </p>
                </p>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: "19px", marginTop: "20px" }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  BUSINESS DETAILS
                </p>

                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Hours of Operation
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.businessDetail?.hoursOfOperation
                      ? businessData?.businessDetail?.hoursOfOperation
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Link to menue
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.businessDetail?.menuLink
                      ? businessData?.businessDetail?.menuLink
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Instagram
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.businessDetail?.instagram
                      ? businessData?.businessDetail?.instagram
                      : "--"}
                  </p>
                </p>
              </Grid>
            </Grid>
          </MainGridBody>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
            }}
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginTop: "20px",
                }}
              >
                PRIMARY CONTACT
              </p>

              <Grid item container lg={12} md={12} sm={12} xs={12}>
                {businessData?.businessDetail ? (
                  <>
                    {businessData?.businessDetail?.isCallPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Call
                      </Button>
                    ) : null}
                    {businessData?.businessDetail?.isEmailPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Email
                      </Button>
                    ) : null}
                    {businessData?.businessDetail?.isTextPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Text
                      </Button>
                    ) : null}
                    {businessData?.businessDetail?.isOtherPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Other
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      fontFamily: "inter",
                    }}
                  >
                    Primary contact not found
                  </p>
                )}
              </Grid>
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginTop: "20px",
                }}
              >
                BOROUGHS
              </p>
              <Grid item container lg={12} md={12} sm={12} xs={12}>
                {businessData.userBorough.length > 0 ? (
                  businessData.userBorough.map((userBoroughs, i) => {
                    if (userBoroughs?.borough?.name) {
                      return (
                        <Button
                          style={{
                            backgroundColor: "rgb(115, 29, 216)",
                            borderRadius: "67px",
                            color: "white",
                            marginLeft: "5px",
                            marginTop: "4px",
                          }}
                        >
                          {userBoroughs?.borough?.name}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      fontFamily: "inter",
                    }}
                  >
                    Borough not found
                  </p>
                )}
              </Grid>
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginTop: "20px",
                }}
              >
                CUISINE TYPE
              </p>
              <Grid item container lg={12} md={12} sm={12} xs={12}>
                {businessData.userCuisineType.length > 0 ? (
                  businessData.userCuisineType.map((cuisine, i) => {
                    if (cuisine?.cuisineType?.name) {
                      return (
                        <Button
                          style={{
                            backgroundColor: "rgb(115, 29, 216)",
                            borderRadius: "67px",
                            color: "white",
                            marginLeft: "5px",
                            marginTop: "4px",
                          }}
                        >
                          {cuisine?.cuisineType?.name}
                        </Button>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      fontFamily: "inter",
                    }}
                  >
                    Cuisine Type not found
                  </p>
                )}
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ marginTop: "29px" }}
              >
                <Button
                  onClick={() =>
                    history.push("/payment/view", { ...businessData })
                  }
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  View Transaction Details
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {approveModal && (
        <ApproveModal
          approveModal={approveModal}
          setapproveModal={setapproveModal}
          userId={businessData?.id}
          type={businessData?.type}
        />
      )}
      {ProfileModal && (
        <UserProfileModal
          ProfileModal={ProfileModal}
          setProfileModal={setProfileModal}
          showProfile={businessData?.businessDetail?.profileImage}
          ProfileLink={businessData?.businessDetail?.profileImage}
          UploadHeadshotStatus={false}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(BusinessProfile);
