import {
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import TableComponent from "../../components/Table";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post, put } from "../../../utils/api";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Close } from "@material-ui/icons";
import { alpha } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
    padding: "40px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 80%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "transparent",
    border: "1px solid gray",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function EditPositionModal({
  EditModal,
  setEditModal,
  Name,
  setName,
  Status,
  setStatus,
  getPosition,
  PositionId,
  setPositionId,
}) {
  const redux = useSelector((data) => {
    return data;
  });
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const history = useHistory();
  const EditBorough = async () => {
    const body = {
      name: Name,
      status: Status,
    };
    try {
      // console.log("body", body);
      const editPosition = await put(`${API.editPosition}/${PositionId}`, body);
      // console.log("editPosition", editPosition);
      if (editPosition.statusCode === 202) {
        enqueueSnackbar(editPosition.message, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      } else {
        enqueueSnackbar(editPosition.message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }
      setEditModal(false);
      getPosition();
      removeFiledsValue();
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const removeFiledsValue = () => {
    setName("");
    setStatus("enable");
    setEditModal(false);
  };
  return (
    <Grid>
      <Modal
        hideBackdrop
        open={EditModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid container>
            <Grid item container sm={12} md={12} lg={12} xs={12}>
              <Grid item sm={12} md={6} lg={6} xs={6}></Grid>
              <Grid
                item
                sm={12}
                md={6}
                lg={6}
                xs={6}
                onClick={() => removeFiledsValue()}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <Close style={{ fontSize: "27px" }} />
              </Grid>
            </Grid>
          </Grid>
          <h2
            id="child-modal-title"
            style={{ color: "#333", fontSize: "30px", textAlign: "center" }}
          >
            Edit Position
          </h2>
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cenetr",
            }}
          >
            <Grid item lg={8} md={8} sm={12} xs={12} style={{ margin: "11px" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Name
              </InputLabel>
              <FormControl fullWidth variant="standard">
                <BootstrapInput
                  fullWidth
                  placeholder="Name"
                  id="bootstrap-input"
                  value={Name}
                  autoComplete="off"
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} style={{ margin: "11px" }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Status
              </InputLabel>
              <FormControl fullWidth variant="standard">
                <NativeSelect
                  defaultValue={Status}
                  disableUnderline={true}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{
                    border: "1px solid #948787",
                    padding: "5px",
                    borderRadius: "4px",
                  }}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"enable"}>Enable</option>
                  <option value={"disable"}>Disable</option>
                  {/* <option value={"delete"}>Delete</option> */}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                sx={6}
                justify="space-between"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => removeFiledsValue()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "rgb(115, 29, 216)",
                    border: "1px solid rgb(115, 29, 216)",
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
              <Grid item lg={6} md={6} sm={6} sx={6}>
                <Button
                  onClick={() => EditBorough()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "rgb(115, 29, 216)",
                    border: "1px solid rgb(115, 29, 216)",
                  }}
                >
                  EDIT
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(EditPositionModal);
