import * as types from "../../constants";
import {
  signUp as authSignUp,
  resetPassword as authResetPassword,
} from "../../services/authService";

export default class LoginAction {
  static GET_LOGIN = "GET_LOGIN";
  static GET_LOGOUT = "GET_LOGOUT";
  static BUSINESS_TABLE_PAGENUMBER = "BUSINESS_TABLE_PAGENUMBER";
  static SHIFT_TABLE_PAGENUMBER = "SHIFT_TABLE_PAGENUMBER";
  static BOROUGH_TABLE = "BOROUGH_TABLE";
  static POSITION_TABLE = "POSITION_TABLE";
  static VENUETYPE_TABLE = "VENUETYPE_TABLE";
  static SHIFTER_TABLE = "SHIFTER_TABLE";
  static CUISINE_TABLE = "CUISINE_TABLE";
  static PAYMENTDETAIL_TABLE = "PAYMENTDETAIL_TABLE";
  static BILLING_TABLE = "BILLING_TABLE";
  static getLogin(data) {
    return {
      type: this.GET_LOGIN,
      data,
    };
  }
  static getLogout(data) {
    return {
      type: this.GET_LOGOUT,
      // data,
    };
  }
  static getBusinessTablePageNumber(data) {
    return {
      type: this.BUSINESS_TABLE_PAGENUMBER,
      data,
    };
  }
  static getShiftTablePageNumber(data) {
    return {
      type: this.SHIFT_TABLE_PAGENUMBER,
      data,
    };
  }
  static getBoroughTablePageNumber(data) {
    return {
      type: this.BOROUGH_TABLE,
      data,
    };
  }
  static getPositionTablePageNumber(data) {
    return {
      type: this.POSITION_TABLE,
      data,
    };
  }
  static getVenueTypeTablePageNumber(data) {
    return {
      type: this.VENUETYPE_TABLE,
      data,
    };
  }
  static getShifterTablePageNumber(data) {
    return {
      type: this.SHIFTER_TABLE,
      data,
    };
  }
  static getCuisineTypeTablePageNumber(data) {
    return {
      type: this.CUISINE_TABLE,
      data,
    };
  }
  static getPaymentDetailTablePageNumber(data) {
    return {
      type: this.PAYMENTDETAIL_TABLE,
      data,
    };
  }
  static getBillingTablePageNumber(data) {
    return {
      type: this.BILLING_TABLE,
      data,
    };
  }
}

// export function signIn(credentials) {
//   // console.log(credentials, "fn");
//   return async (dispatch) => {
//     // dispatch({ type: types.AUTH_SIGN_IN_REQUEST });

//     return authSignIn(credentials)
//       .then((response) => {
//         if (response.Status === 200) {
//           dispatch({
//             type: types.AUTH_SIGN_IN_SUCCESS,
//             payload: response.Data,
//           });
//         } else throw response;
//       })
//       .catch((error) => {
//         // dispatch({ type: types.AUTH_SIGN_IN_FAILURE });
//         throw error;
//       });
//   };
// }

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST });

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE });
        throw error;
      });
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST });

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE });
        throw error;
      });
  };
}
