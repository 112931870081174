import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import TableComponent from "../../components/Table";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post } from "../../../utils/api";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Setting({}) {
  const history = useHistory();
  const redux = useSelector((data) => {
    return data;
  });

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Settings
          </Typography>
        </Grid>
        {/* <Grid item>
          <SearchComponent />
        </Grid> */}
      </Grid>

      <Grid container>
        <Grid
          item
          container
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <Grid
            item
            style={{
              fontSize: "22px",
              border: "1px solid #c8c8c8",
              padding: "10px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/setting/borough")}
          >
            <Typography variant="h5">Boroughs</Typography>
          </Grid>
          <Grid
            item
            style={{
              fontSize: "22px",
              border: "1px solid #c8c8c8",
              padding: "10px",
              borderRadius: "4px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/setting/position")}
          >
            <Typography variant="h5">Positions</Typography>
          </Grid>
          <Grid
            item
            style={{
              fontSize: "22px",
              border: "1px solid #c8c8c8",
              padding: "10px",
              borderRadius: "4px",
              marginTop: "10px",

              cursor: "pointer",
            }}
            onClick={() => history.push("/setting/venueType")}
          >
            <Typography variant="h5">Venue Type</Typography>
          </Grid>
          <Grid
            item
            style={{
              fontSize: "22px",
              border: "1px solid #c8c8c8",
              padding: "10px",
              borderRadius: "4px",
              marginTop: "10px",

              cursor: "pointer",
            }}
            onClick={() => history.push("/setting/cuisineType")}
          >
            <Typography variant="h5">Cuisine Type</Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(Setting);
