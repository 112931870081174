import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, CheckCircle } from "@material-ui/icons";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Edit2, Eye } from "react-feather";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UploadButton } from "react-uploader";
import { withTheme } from "styled-components/macro";
import { Uploader } from "uploader"; // Installed by "react-uploader".
import API, { get, post, put } from "../../../utils/api";
import UserProfileModal from "./UserProfileModal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function EditShifter({}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const [businessData, setBusinessData] = useState(location.state);
  const [BusinessFirstName, setBusinessFirstName] = useState(
    businessData?.firstName
  );
  const [BusinessLastName, setBusinessLastName] = useState(
    businessData?.lastName
  );
  const [BusinessEmail, setBusinessEmail] = useState(businessData?.email);
  const [BusinessPhone, setBusinessPhone] = useState(businessData?.phone);

  const [BusinessZipcode, setBusinessZipcode] = useState(businessData?.zipCode);

  const [BusinessAddress, setBusinessAddress] = useState(businessData?.address);

  const [Experience, setExperience] = useState(
    businessData?.shifterDetail?.experience
  );
  const [Skill, setSkill] = useState(businessData?.shifterDetail?.skills);
  const [UploadHeadshotStatus, setUploadHeadshotStatus] = useState(false);
  const [UploadResumeStatus, setUploadResumeStatus] = useState(false);
  const [showProfile, setshowProfile] = useState("");

  const [Vaccinated, setVaccinated] = useState(
    businessData?.shifterDetail?.vaccinated
  );
  const [Status, setStatus] = useState(businessData?.status);
  const [ProfileLink, setProfileLink] = useState(
    businessData?.shifterDetail?.profileLink
  );
  const [ResumeLink, setResumeLink] = useState(
    businessData?.shifterDetail?.resumeLink
  );

  const [isCallPrimary, setisCallPrimary] = useState(
    businessData?.shifterDetail?.isCallPrimary
  );
  const [isEmailPrimary, setisEmailPrimary] = useState(
    businessData?.shifterDetail?.isEmailPrimary
  );
  const [isOtherPrimary, setisOtherPrimary] = useState(
    businessData?.shifterDetail?.isOtherPrimary
  );
  const [isTextPrimary, setisTextPrimary] = useState(
    businessData?.shifterDetail?.isTextPrimary
  );
  const [open, setOpen] = useState(false);
  const [ProfileModal, setProfileModal] = useState(false);
  const theme = useTheme();

  const history = useHistory();
  const [personName, setPersonName] = useState([]);
  const [BoroughIds, setBoroughIds] = useState([]);
  const [personName2, setPersonName2] = useState([]);
  const [Boroughs, setBoroughs] = useState([]);
  const [Positions, setPositions] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: false };

  const redux = useSelector((data) => {
    return data;
  });
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName2(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    getBorough();
    getPosition();
    getBusinessUserBorough();
    getBusinessUserPosition();
  }, []);
  const getBorough = async () => {
    try {
      const getBoroughRes = await get(API.getAllBoroughs);
      setBoroughs(getBoroughRes.length === 0 ? [] : getBoroughRes);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const getPosition = async () => {
    try {
      const getPositionRes = await get(API.getAllPosition);

      setPositions(getPositionRes.length === 0 ? [] : getPositionRes);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const getBusinessUserBorough = () => {
    const boroughArr = [];

    businessData?.userBorough?.map((boroughDetails, i) => {
      boroughArr.push(boroughDetails?.borough?.name);
    });
    setPersonName(boroughArr);
  };
  const getBusinessUserPosition = () => {
    const positionArr = [];
    businessData?.shifterPosition?.map((positionDetails, i) => {
      positionArr.push(positionDetails?.position?.name);
    });
    setPersonName2(positionArr);
  };

  const showError = (message) => {
    return enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const updateResume = async () => {
    let formData = new FormData();

    formData.append("shifterId", businessData?.id);

    formData.append("resume", ResumeLink[0]);

    // formData.append("headShot", showProfile);

    // for (var [key, value] of formData.entries()) {
    //   console.log(key, value);
    // }
    try {
      const uploadResume = await post(API.updateResume, formData);
      // console.log("uploadResume", uploadResume);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const updateHeadshot = async () => {
    let formData = new FormData();
    formData.append("shifterId", businessData?.id);
    formData.append("headShot", showProfile);

    try {
      const uploadHeadshot = await post(API.updateHeadshot, formData);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const updateBusinessUser = async () => {
    setOpen(true);
    const bIds = [];
    for (let i = 0; i < Boroughs.length; i++) {
      for (let j = 0; j < personName.length; j++) {
        if (Boroughs[i].name === personName[j]) {
          bIds.push(Boroughs[i].id);
        }
      }
    }
    const pIds = [];
    for (let i = 0; i < Positions.length; i++) {
      for (let j = 0; j < personName2.length; j++) {
        if (Positions[i].name === personName2[j]) {
          pIds.push(Positions[i].id);
        }
      }
    }
    if (!BusinessEmail) return showError("Email is required");
    else if (!BusinessFirstName) return showError("First Name is required");
    else if (!BusinessPhone) return showError("Phone is required");
    else if (!BusinessAddress) return showError("Address is required");
    else if (!BusinessZipcode) return showError("Zip code is required");
    else if (!BusinessLastName) return showError("Last Name is required");
    else if (!Experience) return showError("Hours of operation is required");
    else if (!Skill) return showError("Menu link is required");
    else if (!Vaccinated) return showError("Vaccinated is required");

    const body = {
      userDetails: {
        email: BusinessEmail,
        userType: businessData?.type,
        firstName: BusinessFirstName,
        lastName: BusinessLastName,
        phone: BusinessPhone,
        address: BusinessAddress,
        zipCode: BusinessZipcode,
        userId: businessData?.id,
        status: Status,
      },
      shifterDetails: {
        vaccinated: Vaccinated,
        experience: Experience,
        skills: Skill,
        isEmailPrimary: isEmailPrimary,
        isTextPrimary: isTextPrimary,
        isCallPrimary: isCallPrimary,
        isOtherPrimary: isOtherPrimary,
        borough: bIds,
        shifterPosition: pIds,
      },
    };
    // console.log(body, body);

    try {
      const updateShifterDetails = await put(API.updateShifter, body);
      if (UploadResumeStatus) {
        updateResume();
      }
      if (UploadHeadshotStatus) {
        updateHeadshot();
      }
      setOpen(false);
      if (updateShifterDetails) {
        showSuccess("Shifter User updated successfully!");
        history.push("/dashboard/shifter");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };

  const changeResume = (e) => {
    setResumeLink(e.target.files);
    setUploadResumeStatus(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />

      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() => history.push("/dashboard/shifter")}
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            Edit Shifterr Details
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginBottom: "120px", flexDirection: "column" }}
      >
        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "13px", marginBottom: "4px" }}>Basic Details</p>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Status
            </InputLabel>
            <FormControl fullWidth>
              <NativeSelect
                disableUnderline={true}
                defaultValue={Status}
                onChange={(e) => setStatus(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
                style={{
                  border: "1px solid rgb(206, 198, 198)",
                  padding: "3px",
                }}
              >
                <option value={"enable"}>Enable</option>
                <option value={"pending"}>Pending</option>
                <option value={"disable"}>Disable</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Profile Picture
            </InputLabel>
            <img
              src={
                UploadHeadshotStatus
                  ? URL.createObjectURL(showProfile)
                  : ProfileLink
              }
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "55px",
                cursor: "pointer",
              }}
              onClick={() => setProfileModal(true)}
            />

            <Grid style={{ marginTop: "25px", marginBottom: "20px" }}>
              <UploadButton
                uploader={uploader}
                options={options}
                onComplete={(files) => {
                  if (files.length > 0) {
                    setshowProfile(files[0]?.originalFile?.file);
                    setUploadHeadshotStatus(true);
                  }
                }}
              >
                {({ onClick }) => (
                  <Button
                    style={{
                      backgroundColor: "rgb(115, 29, 216)",
                      color: "white",
                    }}
                    startIcon={<Edit2 style={{ fontSize: "10px" }} />}
                    onClick={onClick}
                  >
                    Change Profile
                  </Button>
                )}
              </UploadButton>
              <Button
                onClick={() => window.open(ProfileLink)}
                variant="contained"
                component="label"
                style={{
                  backgroundColor: "rgb(115, 29, 216)",
                  color: "white",
                  marginLeft: "5px",
                }}
                startIcon={<Eye style={{ fontSize: "10px" }} />}
              >
                View Profile
              </Button>
            </Grid>
          </Grid>
          <Grid item sm={12} lg={12} md={12} sx={12} mt={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              First Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="First Name"
              value={BusinessFirstName}
              onChange={(e) => setBusinessFirstName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid item sm={12} lg={12} md={12} sx={12} mt={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Last Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Last Name"
              value={BusinessLastName}
              onChange={(e) => setBusinessLastName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Email
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Email"
              value={BusinessEmail}
              onChange={(e) => setBusinessEmail(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Phone Number
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Phone Number"
              value={BusinessPhone}
              onChange={(e) => setBusinessPhone(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Zip Code
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Zip Code"
              value={BusinessZipcode}
              onChange={(e) => setBusinessZipcode(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                flexDirection: "column",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Address
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Address"
              value={BusinessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                flexDirection: "column",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "14px", marginBottom: "4px" }}>
            Shifterr Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Experience
            </InputLabel>
            <TextField
              fullWidth
              value={Experience}
              onChange={(e) => setExperience(e.target.value)}
              placeholder="Experience"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Skills
            </InputLabel>
            <TextField
              fullWidth
              value={Skill}
              onChange={(e) => setSkill(e.target.value)}
              placeholder="Skills"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Vaccinated
            </InputLabel>
            <TextField
              fullWidth
              value={Vaccinated}
              onChange={(e) => setVaccinated(e.target.value)}
              placeholder="Vaccinated"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>

          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Resume
            </InputLabel>
            <Button
              variant="contained"
              component="label"
              style={{ backgroundColor: "rgb(115, 29, 216)", color: "white" }}
            >
              Upload Resume
              <input
                onChange={(e) => changeResume(e)}
                hidden
                accept="application/pdf"
                multiple
                type="file"
              />
            </Button>

            <Button
              onClick={() => window.open(ResumeLink)}
              style={{
                backgroundColor: "rgb(115, 29, 216)",
                color: "white",
                marginLeft: "5px",
              }}
            >
              View Resume
            </Button>
            {ResumeLink?.length > 0 ? (
              ResumeLink[0]?.name ? (
                <p style={{ fontSize: "10px" }}>
                  {ResumeLink[0]?.name} uploaded{" "}
                  <CheckCircle style={{ color: "green", fontSize: "12px" }} />
                </p>
              ) : null
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "16px" }}
        >
          <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
            Primary Contact Details
          </InputLabel>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCallPrimary}
                  onChange={(e) => setisCallPrimary(e.target.checked)}
                />
              }
              label="Is Call Primary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEmailPrimary}
                  onChange={(e) => setisEmailPrimary(e.target.checked)}
                />
              }
              label="Is Email Primary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isOtherPrimary}
                  onChange={(e) => setisOtherPrimary(e.target.checked)}
                />
              }
              label="Is Other Primary"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTextPrimary}
                  onChange={(e) => setisTextPrimary(e.target.checked)}
                />
              }
              label="Is Text Primary"
            />
          </FormGroup>
        </Grid>
        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "14px", marginBottom: "9px" }}>
            Boroughs Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                style={{ marginLeft: "10px" }}
              >
                Select
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                fullWidth
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      return (
                        <Chip
                          key={value}
                          label={value}
                          style={{ marginLeft: "4px", flexDirection: "column" }}
                        />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Boroughs.map((borough) => {
                  return (
                    <MenuItem
                      key={borough.name}
                      value={borough.name}
                      style={getStyles(borough.name, personName, theme)}
                    >
                      {borough.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "14px", marginBottom: "9px" }}>
            Position Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                style={{ marginLeft: "10px" }}
              >
                Select
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                fullWidth
                multiple
                value={personName2}
                onChange={handleChange2}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      return (
                        <Chip
                          key={value}
                          label={value}
                          style={{ marginLeft: "4px", flexDirection: "column" }}
                        />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Positions.map((position) => {
                  return (
                    <MenuItem
                      key={position.name}
                      value={position.name}
                      style={getStyles(position.name, personName2, theme)}
                    >
                      {position.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => updateBusinessUser()}
            style={{ backgroundColor: "rgb(115, 29, 216)", color: "white" }}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
      {ProfileModal && (
        <UserProfileModal
          ProfileModal={ProfileModal}
          setProfileModal={setProfileModal}
          showProfile={showProfile}
          ProfileLink={ProfileLink}
          UploadHeadshotStatus={UploadHeadshotStatus}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(EditShifter);
