import {
  Button,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import LoginAction from "../../../redux/actions/authActions";
import API, { post } from "../../../utils/api";
import ShifterDetailsTable from "../../components/ShifterDetailsTable";
import PaginationComponent from "../../components/TablePagination";

function Shifter({}) {
  const dispatch = useDispatch();

  const redux = useSelector((data) => {
    return data;
  });
  const [TableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [PageNumber, setPageNumber] = useState(
    redux?.authReducer?.ShifterTablePageNumber
  );
  const history = useHistory();
  const [BusinessStatus, setBusinessStatus] = useState([
    "All",
    "Pending",
    "Enable",
    "Disable",
    "Delete",
  ]);
  const [SelectedBusinessStatus, setSelectedBusinessStatus] = useState("All");
  const [BusinessSearch, setBusinessSearch] = useState([
    "Email",
    "First Name",
    "Last Name",
  ]);
  const [
    SelectedBusinessSearchCategory,
    setSelectedBusinessSearchCategory,
  ] = useState("Email");
  const [
    SelectedBusinessSearchTextInput,
    setSelectedBusinessSearchTextInput,
  ] = useState("");

  const [TotalRows, setTotalRows] = useState(1);
  const [TableColumn, setTableColumn] = useState([
    "S.NO",
    "First Name",
    "Last Name",
    "Type",
    "Email",
    "Phone",
    "Status",
    "Edit",
    "View",
  ]);
  useEffect(() => {
    getBusiness();
  }, [PageNumber, limit, SelectedBusinessStatus]);

  const getBusiness = async () => {
    try {
      const isEmail =
        SelectedBusinessSearchCategory === "Email" &&
        SelectedBusinessSearchTextInput
          ? `email=${SelectedBusinessSearchTextInput}&`
          : "";

      const isName1 =
        SelectedBusinessSearchCategory === "First Name" &&
        SelectedBusinessSearchTextInput
          ? `firstName=${SelectedBusinessSearchTextInput}&`
          : "";
      const isName2 =
        SelectedBusinessSearchCategory === "Last Name" &&
        SelectedBusinessSearchTextInput
          ? `lastName=${SelectedBusinessSearchTextInput}&`
          : "";

      const getBusinessRes = await post(
        `${
          API.getShifter
        }?${isEmail}${isName1}${isName2}limit=${limit}&pageNumber=${PageNumber}&status=${SelectedBusinessStatus.toLowerCase()}`
      );
      console.log("getBusinessRes", getBusinessRes);
      setTableData(
        getBusinessRes?.data?.length > 0 ? getBusinessRes?.data : []
      );
      setTotalRows(
        getBusinessRes?.data?.length > 0 ? getBusinessRes?.count : 0
      );
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };

  useEffect(() => {
    UpdatePageNumberOfShifterTable();
  }, [SelectedBusinessStatus]);

  const UpdatePageNumberOfShifterTable = () => {
    dispatch(LoginAction.getShiftTablePageNumber(1));
    setPageNumber(1);
  };

  const searchFilter = () => {
    getBusiness();
  };

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Shifterr
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "31px" }}
        >
          <Grid item>
            <FormControl>
              <NativeSelect
                defaultValue={"All"}
                disableUnderline={true}
                onChange={(e) => setSelectedBusinessStatus(e.target.value)}
                style={{
                  border: "1px solid rgb(148 148 148)",
                  width: "100px",
                  borderRadius: "4px",
                  padding: "3px",
                  marginBottom: "10px",
                }}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                {BusinessStatus.map((status, i) => {
                  return <option value={status}>{status}</option>;
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item>
              <FormControl>
                <NativeSelect
                  defaultValue={"All"}
                  disableUnderline={true}
                  onChange={(e) =>
                    setSelectedBusinessSearchCategory(e.target.value)
                  }
                  placeholder="select"
                  style={{
                    border: "1px solid rgb(148 148 148)",
                    width: "100px",
                    borderRadius: "4px",
                    padding: "3px",
                    marginBottom: "10px",
                  }}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  {BusinessSearch.map((status, i) => {
                    return (
                      <>
                        <option value={status}>{status}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <TextField
              lin
              InputProps={{ disableUnderline: true }}
              style={{
                marginTop: "1px",
                marginLeft: "3px",
                border: "1px solid rgb(148 148 148)",
                padding: "1px 1px 0px 7px",
                borderRadius: "3px",
                height: "35px",
              }}
              placeholder={`Search by ${SelectedBusinessSearchCategory}`}
              onChange={(e) =>
                setSelectedBusinessSearchTextInput(e.target.value)
              }
            />
            <Button
              variant="contained"
              style={{
                color: "rgb(255 255 255 / 87%)",
                height: "37px",
                width: "118px",
                marginLeft: "4px",
                backgroundColor: "rgb(115, 29, 216)",
              }}
              onClick={() => searchFilter()}
            >
              Search
            </Button>
          </Grid>
          <ShifterDetailsTable
            TableData={TableData}
            TableColumn={TableColumn}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PaginationComponent
            limit={limit}
            setLimit={setLimit}
            pageNumber={PageNumber}
            setPageNumber={setPageNumber}
            totalRows={TotalRows}
            from="shifterDetailTable"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(Shifter);
