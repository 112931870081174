import React from "react";

function UserDropdown() {
  // const handleSignOut = async () => {
  //   // const payload = {
  //   //   token: userdata?.authReducer?.data?.token,
  //   // };
  //   axios({
  //     // url: "http://localhost:3000/api/users/logout",
  //     url: "https://api.yieldbites.com/api/users/logout",
  //     method: "POST",
  //     token: userdata?.authReducer?.data?.token,
  //     headers: {
  //       "x-user-jwt": userdata?.authReducer?.data?.token,
  //       // "Content-Type": "application/json",
  //     },
  //   })
  //     .then(async (response) => {
  //       history.push("/auth/sign-in");
  //       if (response.data.success) {
  //         await dispatch(LoginAction.getLogout());
  //         //   history.push("/dashboard/analytics");
  //       } else {
  //         alert(response.data.message);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //   // await dispatch(LoginAction.getLogout());
  //   // history.push("/auth/sign-in");
  // };

  return (
    <React.Fragment>
      {/* <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Power />
        </IconButton>
      </Tooltip> */}
      {/* <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu> */}
    </React.Fragment>
  );
}

export default UserDropdown;
