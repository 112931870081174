import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";

import { useHistory, useLocation } from "react-router-dom";

import { ArrowBack } from "@material-ui/icons";
import styled from "styled-components/macro";
import API, { get } from "../../../utils/api";
import moment from "moment";

const MainGridBody = styled(Box)`
  border: 1px solid rgba(207, 207, 207, 1);
  border-radius: 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  // background-color: red;

  ${(props) => props.theme.breakpoints.down("sm")} {
    border: 1px solid rgba(207, 207, 207, 1);
    border-radius: 15px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    // background-color: red;
  }
`;

function ViewPaymentDetail({}) {
  const location = useLocation();
  const history = useHistory();
  const [shifterData, setShifterData] = useState(location.state);
  const [Transaction, setTransaction] = useState([]);
  console.log("data----", shifterData);

  useEffect(() => {
    getTransactionDetail();
  }, []);

  const getTransactionDetail = async () => {
    if (shifterData.type == "business") {
      const getTransactionDetailRes = await get(
        `${API.getBusinessTransactionDetails}/${shifterData?.id}`
      );
      setTransaction(
        getTransactionDetailRes.length > 0 ? getTransactionDetailRes : []
      );
      console.log("getTransactionDetailRes", getTransactionDetailRes);
    } else {
      const getTransactionDetailRes = await get(
        `${API.getShifterTransactionDetails}/${shifterData?.id}`
      );
      setTransaction(
        getTransactionDetailRes.length > 0 ? getTransactionDetailRes : []
      );
      console.log("getTransactionDetailRes", getTransactionDetailRes);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() =>
              history.push(
                shifterData.type === "business"
                  ? "/dashboard/business"
                  : "/dashboard/shifter"
              )
            }
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            {shifterData.type === "business" ? "Business" : "Shifterrs"} Payment
            Details
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            margin: "24px 0 0 6px",
            // flexDirection: "column",
            fontWeight: "700",
          }}
        >
          <p>Payment History</p>
        </Grid>

        {Transaction.length > 0 ? (
          Transaction.map((getTransaction, i) => {
            return (
              <Grid
                item
                container
                lg={4}
                md={4}
                sm={12}
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    border: "1px solid rgba(207, 207, 207, 1)",
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "34px",
                        height: "34px",
                        borderRadius: "42px",
                      }}
                      src={
                        shifterData?.businessDetail
                          ? shifterData?.businessDetail?.profileImage
                          : shifterData?.shifterDetail?.profileLink
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px 0px 10px 0px",
                    }}
                  >
                    <p
                      style={{
                        margin: "3px 0px 0 6px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Name:</span>{" "}
                      {shifterData?.firstName + " " + shifterData?.lastName}
                    </p>
                    <p
                      style={{
                        margin: "3px 0px 0 6px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Shift Amount:</span>{" "}
                      {getTransaction?.transaction?.shiftAmount
                        ? getTransaction?.transaction?.shiftAmount
                        : getTransaction?.shift?.transaction?.shiftAmount}
                    </p>
                    <p
                      style={{
                        margin: "3px 0px 0 6px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Tip:</span>{" "}
                      {getTransaction?.transaction?.tip
                        ? getTransaction?.transaction?.tip
                        : getTransaction?.shift?.transaction?.tip}
                    </p>
                    {getTransaction?.shift ? (
                      <p
                        style={{
                          margin: "3px 0px 0 6px",
                          fontSize: "10px",
                          width: "100%",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>Promo Code:</span>{" "}
                        {getTransaction?.shift?.transaction?.promoCode
                          ? getTransaction?.shift?.transaction?.promoCode
                          : "--"}
                      </p>
                    ) : null}
                    {getTransaction?.shift ? (
                      <p
                        style={{
                          margin: "3px 0px 0 6px",
                          fontSize: "10px",
                          width: "100%",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Application Fee:
                        </span>{" "}
                        {getTransaction?.shift?.transaction?.applicationFee
                          ? getTransaction?.shift?.transaction?.applicationFee
                          : 0}
                      </p>
                    ) : null}
                    <div
                      style={{
                        border: "1px solid #d3d3d3c4",
                        margin: "5px",
                        width: "93% ",
                      }}
                    ></div>
                    <p
                      style={{
                        margin: "3px 0px 0 6px",
                        fontSize: "10px",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>Total Amount:</span>{" "}
                      {getTransaction?.transaction?.totalAmount
                        ? getTransaction?.transaction?.totalAmount
                        : getTransaction?.shift?.transaction?.totalAmount}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <p style={{ margin: "0px 0px 0 12px", fontSize: "12px" }}>
            Payment History not found.
          </p>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(ViewPaymentDetail);
