import {
  TableBody,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";

import React from "react";
import { Edit } from "react-feather";

import { useHistory } from "react-router-dom";

function CuisineTypeTable({
  TableData,
  TableColumn,
  EditModal,
  setEditModal,
  Name,
  setName,
  Status,
  setStatus,
  CuisineTypeId,
  setCuisineTypeId,
  DeleteModal,
  setDeleteModal,
}) {
  const history = useHistory();

  const openEditModal = (row) => {
    setEditModal(true);
    setName(row.name);
    setCuisineTypeId(row.id);
    setStatus(row.status);
  };
  const openDeleteModal = (row) => {
    // console.log("openDeleteModal", row);
    setCuisineTypeId(row.id);
    setDeleteModal(true);
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#731DD8" }}>
            <TableRow>
              {TableColumn.map((data) => {
                return (
                  <TableCell
                    style={{ color: "white", cursor: "pointer" }}
                    align="center"
                  >
                    {data}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {TableData.map((row, i) => (
              <TableRow
                key={i}
                style={{ cursor: "pointer" }}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">
                  {row?.name ? row?.name : "--"}
                </TableCell>

                <TableCell align="center">
                  {row?.status === "enable" ? (
                    <Button
                      style={{ backgroundColor: "#D8921F", color: "white" }}
                    >
                      Enable
                    </Button>
                  ) : row?.status === "delete" ? (
                    <Button
                      style={{
                        backgroundColor: "#dc3545",
                        color: "white",
                      }}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: "#6c757d",
                        color: "white",
                      }}
                    >
                      Disable
                    </Button>
                  )}
                </TableCell>
                {row?.status === "delete" ? (
                  <TableCell align="center">
                    <Edit style={{ color: "#6c757d" }} />
                  </TableCell>
                ) : (
                  <TableCell align="center" onClick={() => openEditModal(row)}>
                    <Edit />
                  </TableCell>
                )}
                {row?.status === "delete" ? (
                  <TableCell style={{ cursor: "pointer" }} align="center">
                    <DeleteForever style={{ color: "#6c757d" }} />
                  </TableCell>
                ) : (
                  <TableCell
                    style={{ cursor: "pointer" }}
                    align="center"
                    onClick={() => openDeleteModal(row)}
                  >
                    <DeleteForever />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {TableData.length > 0 ? null : (
          <Grid container justify="center">
            <p style={{ textAlign: "center", margin: "10px", color: "#333" }}>
              Data not found
            </p>
          </Grid>
        )}
      </TableContainer>
    </React.Fragment>
  );
}

export default CuisineTypeTable;
