import {
  TableBody,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import React from "react";
import { Eye } from "react-feather";

function BillingTable({
  TableData,
  TableColumn,
  setViewModalData,
  setOpenViewModal,
  setSelectedBilling,
}) {
  const openModal = (row) => {
    setViewModalData(row);
    setOpenViewModal(true);
    setSelectedBilling(row);
  };
  const viewingModal = (row) => {
    openModal(row);
    setSelectedBilling(row);
  };
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#731DD8" }}>
            <TableRow>
              {TableColumn.map((data) => {
                return (
                  <TableCell style={{ color: "white" }} align="center">
                    {data}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {TableData.map((row, i) => {
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row?.id}
                  </TableCell>
                  <TableCell align="center">
                    {row?.shift?.businessUser
                      ? `${
                          row?.shift?.businessUser?.firstName +
                          " " +
                          row?.shift?.businessUser?.lastName
                        }`
                      : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {row?.totalAmount ? row?.totalAmount : "--"}
                  </TableCell>

                  <TableCell align="center">
                    {row?.shiftAmount ? row?.shiftAmount : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.totalAmount ? row?.totalAmount : "--"}
                  </TableCell>
                  <TableCell align="center" onClick={() => viewingModal(row)}>
                    <Eye style={{ cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              );
              //   }
            })}
          </TableBody>
        </Table>
        {TableData.length > 0 ? null : (
          <Grid container justify="center">
            <p style={{ textAlign: "center", margin: "10px", color: "#333" }}>
              Data not found
            </p>
          </Grid>
        )}
      </TableContainer>
    </React.Fragment>
  );
}

export default BillingTable;
