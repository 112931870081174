import {
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import API, { del, get, post } from "../../../utils/api";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Close } from "@material-ui/icons";
import { alpha } from "@mui/material";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
    padding: "40px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 80%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;

function DeleteCuisineType({
  DeleteModal,
  setDeleteModal,
  CuisineTypeId,
  getCuisineType,
  setStatus,
}) {
  const redux = useSelector((data) => {
    return data;
  });
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const DeleteCuisineType = async () => {
    // console.log("CuisineTypeId", CuisineTypeId);

    const deleteCuisineType = await del(
      `${API.getCuisineType}/${CuisineTypeId}`
    );
    // console.log("deleteCuisineType", deleteCuisineType);
    if (deleteCuisineType.statusCode === 202) {
      enqueueSnackbar(deleteCuisineType.message, {
        variant: "success",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    } else {
      enqueueSnackbar(deleteCuisineType.message, {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
    setDeleteModal(false);
    getCuisineType();
    removeFiledsValue();
  };

  const removeFiledsValue = () => {
    setDeleteModal(false);
    setStatus("enable");
  };
  return (
    <Grid>
      <Modal
        hideBackdrop
        open={DeleteModal}
        onClose={() => setDeleteModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid container>
            <Grid item container sm={12} md={12} lg={12} xs={12}>
              <Grid item sm={12} md={6} lg={6} xs={6}>
                <h2
                  id="child-modal-title"
                  style={{
                    color: "#333",
                    fontSize: "20px",
                  }}
                >
                  Delete Cuisine Type
                </h2>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                lg={6}
                xs={6}
                onClick={() => removeFiledsValue()}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <Close style={{ fontSize: "27px" }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cenetr",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p style={{ fontSize: "15px" }}>
                Are you sure you want to delete this cuisine type?
              </p>
            </Grid>
            <Grid item container lg={12} md={12} sm={12}>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                sx={6}
                // style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => removeFiledsValue()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "gray",
                    border: "1px solid gray",
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={() => DeleteCuisineType()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "#dc3545",
                    border: "1px solid #dc3545",
                  }}
                >
                  DELETE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(DeleteCuisineType);
