import * as React from "react";
import { TablePagination } from "@mui/material";
import { useDispatch } from "react-redux";
import LoginAction from "../../redux/actions/authActions";

export default function PaginationComponent({
  pageNumber,
  setPageNumber,
  limit,
  setLimit,
  totalRows,
  from,
}) {
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    setPageNumber(newPage + 1);
    if (from === "businessTable") {
      dispatch(LoginAction.getBusinessTablePageNumber(newPage + 1));
    } else if (from === "shiftTable") {
      dispatch(LoginAction.getShiftTablePageNumber(newPage + 1));
    } else if (from === "boroughTable") {
      dispatch(LoginAction.getBoroughTablePageNumber(newPage + 1));
    } else if (from === "venueTypeTable") {
      dispatch(LoginAction.getVenueTypeTablePageNumber(newPage + 1));
    } else if (from === "positionTable") {
      dispatch(LoginAction.getPositionTablePageNumber(newPage + 1));
    } else if (from === "shifterDetailTable") {
      dispatch(LoginAction.getShifterTablePageNumber(newPage + 1));
    } else if (from === "CuisineTypeTable") {
      dispatch(LoginAction.getCuisineTypeTablePageNumber(newPage + 1));
    } else if (from === "PaymentTable") {
      dispatch(LoginAction.getPaymentDetailTablePageNumber(newPage + 1));
    } else if (from === "BillingTable") {
      dispatch(LoginAction.getBillingTablePageNumber(newPage + 1));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPageNumber(1);
  };
  return (
    // <Stack>
    <TablePagination
      sx={{
        ".MuiTablePagination-selectLabel": {
          marginBottom: "0rem !important",
        },
        ".MuiTablePagination-displayedRows": {
          marginBottom: "0rem !important",
        },
      }}
      rowsPerPageOptions={[5, 10, 15, 20]}
      component="div"
      count={totalRows}
      rowsPerPage={limit}
      page={pageNumber - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    // </Stack>
  );
}
