import { combineReducers } from "redux";
import authReducer from "./authReducer";
import themeReducer from "./themeReducer";

const rootReducer = combineReducers({
  themeReducer,
  authReducer,
});
export default rootReducer;
// (state, action) =>
// rootReducer(action.type === "GET_LOGOUT" ? undefined : state, action);
