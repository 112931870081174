import {
  Box,
  Button,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  NativeSelect,
  Select,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import TableComponent from "../../components/Table";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post } from "../../../utils/api";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Close } from "@material-ui/icons";
import { alpha } from "@mui/material";
import { useSnackbar } from "notistack";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Calendar, DateRangePicker, DateRange } from "react-date-range";
import { addDays, subDays } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
    padding: "40px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 80%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "transparent",
    border: "1px solid gray",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function CalendarModal({
  ShowCalander,
  setShowCalander,
  StartDate,
  EndDate,
  setStartDate,
  setEndDate,
}) {
  // console.log("StartDate", StartDate, EndDate);
  const [SelectedDate, setSelectedDate] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const redux = useSelector((data) => {
    return data;
  });
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const removeFiledsValue = () => {
    // setName("");
    // setStatus("enable");
    setShowCalander(false);
  };
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setSelectedDate([selection]);
    // console.log(selection); // native Date object
    setStartDate(selection.startDate);
    setEndDate(selection.endDate);
  };

  return (
    <Grid>
      <Modal
        hideBackdrop
        open={ShowCalander}
        onClose={() => setShowCalander(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid container>
            <Grid item container sm={12} md={12} lg={12} xs={12}>
              <Grid item sm={12} md={6} lg={6} xs={6}></Grid>
              <Grid
                item
                sm={12}
                md={6}
                lg={6}
                xs={6}
                onClick={() => removeFiledsValue()}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <Close
                  style={{
                    fontSize: "27px",
                    marginBottom: "14px",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <h2
            id="child-modal-title"
            style={{ color: "#333", fontSize: "30px", textAlign: "center" }}
          >
            Add Position
          </h2> */}
          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cenetr",
            }}
          >
            <DateRange
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              onChange={(e) => handleOnChange(e)}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              ranges={SelectedDate}
              direction="horizontal"
            />
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                sx={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={() => removeFiledsValue()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "rgb(115, 29, 216)",
                    border: "1px solid rgb(115, 29, 216)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(CalendarModal);
