import Axios from "axios";

import { store } from "../redux/store";

let ISDEV = true;
let DEV_URL = "https://api.getshifterr.com";
let LOCAL_URL = "http://localhost:3000";

export const baseUrl = ISDEV ? DEV_URL : LOCAL_URL;

const API = {
  //admin apis
  adminLogin: `${baseUrl}/auth/login`,
  getShifter: `${baseUrl}/admin/shifter`,
  getBusiness: `${baseUrl}/admin/business`,
  getBorough: `${baseUrl}/admin/borough`,
  addBorough: `${baseUrl}/admin/borough/add`,
  editBorough: `${baseUrl}/admin/borough`,
  deleteBorough: `${baseUrl}/admin/borough`,
  Position: `${baseUrl}/admin/position`,
  addPosition: `${baseUrl}/admin/position/add`,
  editPosition: `${baseUrl}/admin/position`,
  deletePosition: `${baseUrl}/admin/position`,
  getVenueType: `${baseUrl}/admin/venueType`,
  addVenueType: `${baseUrl}/admin/venueType/add`,
  updateVenueType: `${baseUrl}/admin/venueType`,
  deleteVenueType: `${baseUrl}/admin/venueType`,
  getShift: `${baseUrl}/admin/shift`,
  getShiftDetails: `${baseUrl}/admin/shift`,
  approveUser: `${baseUrl}/admin/approve`,
  deleteFeedback: `${baseUrl}/admin/feedback/businessToShifter`,
  getFeedbackFromBusinessToShifter: `${baseUrl}/admin/feedback`,
  getAllBoroughs: `${baseUrl}/admin/allBoroughs`,
  getAllPosition: `${baseUrl}/positions/`,
  updateBusinessUser: `${baseUrl}/admin/businessUserDetails`,
  updateResume: `${baseUrl}/admin/shifter/uploadResume`,
  updateHeadshot: `${baseUrl}/admin/shifter/uploadHeadshot`,
  updateShifter: `${baseUrl}/admin/shifterDetails`,
  getAllVenueType: `${baseUrl}/admin/`,
  approveShift: `${baseUrl}/admin/shift/approve`,
  logout: `${baseUrl}/auth/logout`,
  getCuisineType: `${baseUrl}/admin/cuisineType`,
  addCuisineType: `${baseUrl}/admin/cuisineType/add`,
  getAllCuisineType: `${baseUrl}/cuisineType`,
  getShifterTransactionDetails: `${baseUrl}/shift/getShifterTransactionDetails`,
  getBusinessTransactionDetails: `${baseUrl}/shift/getBusinessTransactionDetails`,
  getBilling: `${baseUrl}/admin/billingDetails`,
};

export const get = async (endPoint) => {
  const token = store.getState().authReducer?.data?.token;
  return new Promise((resolve, reject) => {
    Axios.get(endPoint, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        // console.log("error from get api", error.response);
        reject(error);
      });
  });
};

export const post = async (endPoint, data, type) => {
  const token =
    type === "signin" ? "" : store.getState().authReducer?.data?.token;
  return new Promise((resolve, reject) => {
    Axios.post(
      endPoint,
      data,
      token
        ? {
            headers: { Authorization: `Bearer ${token}` },
          }
        : null
    )
      .then((response) => {
        if (response) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const put = async (endpoint, data) => {
  const token = store.getState().authReducer?.data?.token;

  return new Promise((resolve, reject) => {
    Axios.put(endpoint, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const del = async (endpoint, data) => {
  const token = store.getState().authReducer?.data?.token;
  return new Promise((resolve, reject) => {
    Axios.delete(endpoint, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res) {
          resolve(res.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default API;
