import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import API, { get, put } from "../../../utils/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const label = { inputProps: { "aria-label": "Switch demo" } };

function EditBusiness({}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();
  const [businessData, setBusinessData] = useState(location.state);
  const [BusinessLastName, setBusinessLastName] = useState(
    businessData?.lastName
  );
  const [BusinessFirstName, setBusinessFirstName] = useState(
    businessData?.firstName
  );
  const [BusinessEmail, setBusinessEmail] = useState(businessData?.email);
  const [BusinessPhone, setBusinessPhone] = useState(businessData?.phone);
  const [BusinessSoftwareInfo, setBusinessSoftwareInfo] = useState(
    businessData?.businessDetail?.softwareInfo
  );
  console.log("businessData", businessData);
  const [
    BusinessVaccinationRequired,
    setBusinessVaccinationRequired,
  ] = useState(businessData?.businessDetail?.vaccinationRequired);
  const [BusinessZipcode, setBusinessZipcode] = useState(businessData?.zipCode);
  const [BusinessWebsite, setBusinessWebsite] = useState(
    businessData?.businessDetail?.website
  );
  const [BusinessAddress, setBusinessAddress] = useState(businessData?.address);
  const [BusinessVenueName, setBusinessVenueName] = useState(
    businessData?.businessDetail?.venueName
  );
  const [BusinessVenueType, setBusinessVenueType] = useState(
    businessData?.businessDetail?.venueType?.name
  );
  const [BusinessOtherInformation, setBusinessOtherInformation] = useState(
    businessData?.businessDetail?.venueAmbianceDescription
  );
  const [PrimaryContactEmail, setPrimaryContactEmail] = useState(
    businessData?.businessDetail?.primaryContactEmail
  );
  const [PrimaryContactPhoneNumber, setPrimaryContactPhoneNumber] = useState(
    businessData?.businessDetail?.primaryContactNumber
  );
  const [hoursOfOperation, sethoursOfOperation] = useState(
    businessData?.businessDetail?.hoursOfOperation
  );
  const [LinkToMenu, setLinkToMenu] = useState(
    businessData?.businessDetail?.menuLink
  );
  const [Instagram, setInstagram] = useState(
    businessData?.businessDetail?.instagram
  );
  const [SelectedCuisinType, setSelectedCuisinType] = useState(
    businessData?.userCuisineType
  );
  const [CuisinType, setCuisinType] = useState([]);
  const [PolicyDetail, setPolicyDetail] = useState(
    businessData?.businessDetail?.uniformPolicy
  );
  const [TipsPolicy, setTipsPolicy] = useState(
    businessData?.businessDetail?.tipsPolicy
  );
  const [isCallPrimary, setisCallPrimary] = useState(
    businessData?.businessDetail
      ? businessData?.businessDetail?.isCallPrimary
      : false
  );
  const [isEmailPrimary, setisEmailPrimary] = useState(
    businessData?.businessDetail
      ? businessData?.businessDetail?.isEmailPrimary
      : false
  );
  const [isOtherPrimary, setisOtherPrimary] = useState(
    businessData?.businessDetail
      ? businessData?.businessDetail?.isOtherPrimary
      : false
  );
  const [isTextPrimary, setisTextPrimary] = useState(
    businessData?.businessDetail
      ? businessData?.businessDetail?.isTextPrimary
      : false
  );
  const [BankName, setBankName] = useState(
    businessData?.paymentDetails?.bankName
  );

  const [AccountName, setAccountName] = useState(
    businessData?.paymentDetails?.accountName
  );

  const [AccountNumber, setAccountNumber] = useState(
    businessData?.paymentDetails?.accountNumber
  );

  const [IFSCCode, setIFSCCode] = useState(
    businessData?.paymentDetails?.IFSCCode
  );

  const [VenueType, setVenueType] = useState([]);
  const [SelectedVenueType, setSelectedVenueType] = useState(
    businessData?.businessDetail
      ? businessData?.businessDetail?.venueType?.id
      : 1
  );

  const theme = useTheme();

  const history = useHistory();
  const [personName, setPersonName] = useState([]);
  const [Status, setStatus] = useState(businessData?.status);

  const [personName2, setPersonName2] = useState([]);
  const [Boroughs, setBoroughs] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName2(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    getBorough();
    getVenueType();
    getBusinessUserBorough();
    getCuisineType();
    getUserCuisineType();
  }, []);
  const getBorough = async () => {
    try {
      const getBoroughRes = await get(API.getAllBoroughs);

      setBoroughs(getBoroughRes.length === 0 ? [] : getBoroughRes);
    } catch (error) {
      // console.log("error", error);
    }
  };
  const getVenueType = async () => {
    try {
      const getVenueTypeRes = await get(API.getAllVenueType);

      setVenueType(getVenueTypeRes.length === 0 ? [] : getVenueTypeRes);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const getCuisineType = async () => {
    try {
      const getCuisineTypeRes = await get(API.getAllCuisineType);

      setCuisinType(getCuisineTypeRes.length === 0 ? [] : getCuisineTypeRes);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const getUserCuisineType = () => {
    const cuisineData = [];
    businessData?.userCuisineType?.map((cuisine, i) => {
      cuisineData.push(cuisine?.cuisineType?.name);
    });
    setPersonName2(cuisineData);
  };

  const getBusinessUserBorough = () => {
    const boroughArr = [];

    businessData?.userBorough?.map((boroughDetails, i) => {
      boroughArr.push(boroughDetails?.borough?.name);
    });
    setPersonName(boroughArr);
  };

  const showError = (message) => {
    return enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    });
  };

  const updateBusinessUser = async () => {
    const bIds = [];
    for (let i = 0; i < Boroughs.length; i++) {
      for (let j = 0; j < personName.length; j++) {
        if (Boroughs[i].name === personName[j]) {
          bIds.push(Boroughs[i].id);
        }
      }
    }
    const cIds = [];
    for (let i = 0; i < CuisinType.length; i++) {
      for (let j = 0; j < personName2.length; j++) {
        if (CuisinType[i].name === personName2[j]) {
          cIds.push(CuisinType[i].id);
        }
      }
    }
    if (!BusinessEmail) return showError("Email is required");
    else if (!BusinessFirstName) return showError("First Name is required");
    else if (!BusinessLastName) return showError("Last Name is required");
    else if (!BusinessPhone) return showError("Phone is required");
    else if (!BusinessAddress) return showError("Address is required");
    else if (!BusinessZipcode) return showError("Zip code is required");
    else if (!BusinessVenueName) return showError("Venue Name is required");
    else if (!PrimaryContactEmail)
      return showError("Primary Contact Email is required");
    else if (!PrimaryContactPhoneNumber)
      return showError("Primary Contact Phone Number is required");
    else if (!BusinessWebsite) return showError("Website is required");
    // else if (!BusinessOtherInformation)
    //   return showError("Other Information is required");
    else if (!hoursOfOperation)
      return showError("Hours of operation is required");
    else if (!LinkToMenu) return showError("Menu link is required");
    else if (!Instagram) return showError("Instagram is required");
    else if (!CuisinType) return showError("Cuisin Type is required");
    // else if (!BusinessVaccinationRequired)
    //   return showError("Vaccination is required");
    const body = {
      userDetails: {
        email: BusinessEmail,
        userType: businessData?.type,
        firstName: BusinessFirstName,
        lastName: BusinessLastName,
        phone: BusinessPhone,
        address: BusinessAddress,
        zipCode: BusinessZipcode,
        userId: businessData?.id,
        status: Status,
      },
      businessDetails: {
        venueName: BusinessVenueName,
        primaryContactFirstName: BusinessFirstName,
        primaryContactLastName: BusinessLastName,
        primaryContactEmail: PrimaryContactEmail,
        primaryContactNumber: PrimaryContactPhoneNumber,
        website: BusinessWebsite,
        venueAmbianceDescription: BusinessOtherInformation,
        venueTypeId: parseInt(SelectedVenueType),
        isEmailPrimary: isEmailPrimary,
        isCallPrimary: isCallPrimary,
        isTextPrimary: isTextPrimary,
        isOtherPrimary: isOtherPrimary,
        softwareInfo: BusinessSoftwareInfo,
        borough: bIds,
        uniformPolicy: PolicyDetail,
        tipsPolicy: TipsPolicy,
        hoursOfOperation: hoursOfOperation,
        menuLink: LinkToMenu,
        instagram: Instagram,
        cuisineType: cIds,
        vaccinationRequired: BusinessVaccinationRequired,
      },
    };
    console.log("body", body);
    try {
      const updateBusinessDetails = await put(API.updateBusinessUser, body);
      if (updateBusinessDetails) {
        showSuccess("Business User updated successfully!");

        history.push("dashboard/business");
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() => history.push("/dashboard/business")}
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            Edit Business Details
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{ marginBottom: "120px", flexDirection: "column" }}
      >
        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "15px", marginBottom: "4px" }}>Basic Details</p>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{
              marginTop: "12px",
              marginBottom: "10px",
              flexDirection: "column",
            }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Status
            </InputLabel>
            <FormControl fullWidth>
              <NativeSelect
                disableUnderline={true}
                defaultValue={Status}
                onChange={(e) => setStatus(e.target.value)}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
                style={{
                  border: "1px solid rgb(206, 198, 198)",
                  padding: "3px",
                }}
              >
                <option value={"enable"}>Enable</option>
                <option value={"pending"}>Pending</option>
                <option value={"disable"}>Disable</option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              First Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="First Name"
              value={BusinessFirstName}
              onChange={(e) => setBusinessFirstName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Last Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Last Name"
              value={BusinessLastName}
              onChange={(e) => setBusinessLastName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Email
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Email"
              value={BusinessEmail}
              onChange={(e) => setBusinessEmail(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Phone Number
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Phone Number"
              value={BusinessPhone}
              type={"number"}
              onChange={(e) => setBusinessPhone(e.target.value)}
              InputProps={{
                disableUnderline: true,
                inputMode: "numeric",
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Zip Code
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Zip Code"
              value={BusinessZipcode}
              onChange={(e) => setBusinessZipcode(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Website
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Website"
              value={BusinessWebsite}
              onChange={(e) => setBusinessWebsite(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Address
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Address"
              value={BusinessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "14px", marginBottom: "4px" }}>Venue Details</p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "15px", marginBottom: "4px" }}>
              Venue Name
            </InputLabel>
            <TextField
              fullWidth
              placeholder="Venue Name"
              value={BusinessVenueName}
              onChange={(e) => setBusinessVenueName(e.target.value)}
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>

          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Venue Ambiance Description
            </InputLabel>
            <TextField
              fullWidth
              value={BusinessOtherInformation}
              onChange={(e) => setBusinessOtherInformation(e.target.value)}
              placeholder="Venue Ambiance Description"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "15px", marginBottom: "4px" }}>
            Contact Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Primary Contact Email
            </InputLabel>
            <TextField
              fullWidth
              value={PrimaryContactEmail}
              onChange={(e) => setPrimaryContactEmail(e.target.value)}
              placeholder="Primary Contact Email"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Primary Contact Phone Number
            </InputLabel>
            <TextField
              fullWidth
              value={PrimaryContactPhoneNumber}
              onChange={(e) => setPrimaryContactPhoneNumber(e.target.value)}
              placeholder="Primary Contact Phone Number"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "16px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Primary Contact Details
            </InputLabel>
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCallPrimary}
                    onChange={(e) => setisCallPrimary(e.target.checked)}
                  />
                }
                label="Is Call Primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEmailPrimary}
                    onChange={(e) => setisEmailPrimary(e.target.checked)}
                  />
                }
                label="Is Email Primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isOtherPrimary}
                    onChange={(e) => setisOtherPrimary(e.target.checked)}
                  />
                }
                label="Is Other Primary"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTextPrimary}
                    onChange={(e) => setisTextPrimary(e.target.checked)}
                  />
                }
                label="Is Text Primary"
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "15px", marginBottom: "4px" }}>
            Business Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Hours of Operation
            </InputLabel>
            <TextField
              fullWidth
              value={hoursOfOperation}
              onChange={(e) => sethoursOfOperation(e.target.value)}
              placeholder="Hours of Operation"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Link to menu
            </InputLabel>
            <TextField
              fullWidth
              value={LinkToMenu}
              onChange={(e) => setLinkToMenu(e.target.value)}
              placeholder="Link to menu"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Instagram
            </InputLabel>
            <TextField
              fullWidth
              value={Instagram}
              onChange={(e) => setInstagram(e.target.value)}
              placeholder="Instagram"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "12px" }}
          >
            <p
              style={{
                fontSize: "15px",
                marginBottom: "9px",
                flexDirection: "column",
              }}
            >
              Cuisine Type Details
            </p>
            <Grid item sm={12} lg={12} md={12} sx={12}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-multiple-chip-label"
                  style={{ marginLeft: "10px" }}
                >
                  Select
                </InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  fullWidth
                  multiple
                  value={personName2}
                  onChange={handleChange2}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        return (
                          <Chip
                            key={value}
                            label={value}
                            style={{
                              marginLeft: "4px",
                              flexDirection: "column",
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {CuisinType.map((cuisineType) => {
                    return (
                      <MenuItem
                        key={cuisineType.name}
                        value={cuisineType.name}
                        style={getStyles(cuisineType.name, personName2, theme)}
                      >
                        {cuisineType.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p style={{ fontSize: "15px", marginBottom: "4px" }}>
            Policy Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Uniform policy
            </InputLabel>
            <TextField
              fullWidth
              value={PolicyDetail}
              onChange={(e) => setPolicyDetail(e.target.value)}
              placeholder="Uniform policy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px", flexDirection: "column" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Tips policy
            </InputLabel>
            <TextField
              fullWidth
              value={TipsPolicy}
              onChange={(e) => setTipsPolicy(e.target.value)}
              placeholder="Tips policy"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Software Information
            </InputLabel>

            <TextField
              fullWidth
              value={BusinessSoftwareInfo}
              onChange={(e) => setBusinessSoftwareInfo(e.target.value)}
              placeholder="Software Information"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            lg={12}
            md={12}
            sx={12}
            style={{ marginTop: "6px" }}
          >
            <InputLabel style={{ fontSize: "12px", marginBottom: "4px" }}>
              Vaccination
            </InputLabel>
            <Switch
              {...label}
              value={BusinessVaccinationRequired}
              onChange={(e) => setBusinessVaccinationRequired(e.target.checked)}
            />
            {/* <TextField
              fullWidth
              value={BusinessVaccinationRequired}
              onChange={(e) => setBusinessVaccinationRequired(e.target.value)}
              placeholder="Vaccination"
              InputProps={{
                disableUnderline: true,
              }}
              style={{
                border: "1px solid #cec6c6",
                padding: "6px",
                borderRadius: "4px",
                flexDirection: "column",
              }}
            /> */}
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <p
            style={{
              fontSize: "15px",
              marginBottom: "9px",
              flexDirection: "column",
            }}
          >
            Boroughs Details
          </p>
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-multiple-chip-label"
                style={{ marginLeft: "10px" }}
              >
                Select
              </InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                fullWidth
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => {
                      return (
                        <Chip
                          key={value}
                          label={value}
                          style={{ marginLeft: "4px", flexDirection: "column" }}
                        />
                      );
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Boroughs.map((borough) => {
                  return (
                    <MenuItem
                      key={borough.name}
                      value={borough.name}
                      style={getStyles(borough.name, personName, theme)}
                    >
                      {borough.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{ marginTop: "20px", flexDirection: "column" }}
        >
          <Grid item sm={12} lg={12} md={12} sx={12}>
            <FormControl fullWidth>
              <p style={{ fontSize: "15px", marginBottom: "9px" }}>
                Venue Type
              </p>
              <FormControl fullWidth>
                <NativeSelect
                  disableUnderline={true}
                  defaultValue={SelectedVenueType}
                  value={SelectedVenueType}
                  onChange={(e) => setSelectedVenueType(e.target.value)}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                  style={{
                    border: "1px solid rgb(206, 198, 198)",
                    padding: "3px",
                  }}
                >
                  {VenueType.map((venue, i) => {
                    return <option value={venue.id}>{venue.name}</option>;
                  })}
                </NativeSelect>
              </FormControl>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          lg={12}
          md={12}
          sx={12}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => updateBusinessUser()}
            style={{ backgroundColor: "rgb(115, 29, 216)", color: "white" }}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(EditBusiness);
