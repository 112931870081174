import {
  Button,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post } from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import ShiftTable from "./ShiftTable";
import LoginAction from "../../../redux/actions/authActions";

import CalendarModal from "./CalendarModal";
import { Filter, FilterList, FilterListOutlined } from "@material-ui/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

const date = new Date();
function Shift({}) {
  const dispatch = useDispatch();

  const [BusinessStatus, setBusinessStatus] = useState([
    "All",
    "Open",
    "Ongoing",
    "Complete",
    "Hired",
  ]);
  const [BusinessSearch, setBusinessSearch] = useState(["JobTitle"]);
  const [SelectedBusinessStatus, setSelectedBusinessStatus] = useState("");
  const [
    SelectedBusinessSearchCategory,
    setSelectedBusinessSearchCategory,
  ] = useState("JobTitle");
  const [
    SelectedBusinessSearchTextInput,
    setSelectedBusinessSearchTextInput,
  ] = useState("");

  const redux = useSelector((data) => {
    return data;
  });
  const [TableData, setTableData] = useState([]);
  const [ShowCalander, setShowCalander] = useState(false);
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const history = useHistory();

  const [limit, setLimit] = useState(5);
  const [PageNumber, setPageNumber] = useState(
    redux?.authReducer?.shiftTablePageNumber
  );
  const [TotalRows, setTotalRows] = useState(1);
  const [TableColumn, setTableColumn] = useState([
    "S.NO",
    "Job Title",
    "Date",
    "Pay Per Hour",
    "Status",
    "Start Time",
    "End Time",
    "View",
  ]);
  useEffect(() => {
    getShifts();
  }, [PageNumber, limit, SelectedBusinessStatus, StartDate, EndDate]);

  useEffect(() => {
    UpdatePageNumberOfShifterTable();
  }, [SelectedBusinessStatus]);

  const getShifts = async () => {
    try {
      const isJobTitle =
        SelectedBusinessSearchCategory === "JobTitle" &&
        SelectedBusinessSearchTextInput
          ? `jobTitle=${SelectedBusinessSearchTextInput}&`
          : "";

      const isStartDate = StartDate
        ? `startDate=${moment(StartDate).format("YYYY-MM-DD")}&`
        : "";

      const isEndDate = EndDate
        ? `endDate=${moment(EndDate).format("YYYY-MM-DD")}&`
        : "";
      const isStatus = SelectedBusinessStatus
        ? `status=${SelectedBusinessStatus.toLowerCase()}&`
        : "status=all&";

      const getShiftRes = await post(
        `${API.getShift}?${isJobTitle}limit=${limit}&pageNumber=${PageNumber}&${isStatus}${isStartDate}${isEndDate}`
      );

      setTableData(getShiftRes?.data);
      setTotalRows(getShiftRes?.count);
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };

  const searchFilter = () => {
    getShifts();
  };
  const UpdatePageNumberOfShifterTable = () => {
    dispatch(LoginAction.getShiftTablePageNumber(1));
    setPageNumber(1);
  };

  const openCalendar = () => {
    setShowCalander(true);
  };
  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Shifts
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "31px" }}
        >
          <Grid item>
            <FormControl>
              <NativeSelect
                defaultValue={"all"}
                disableUnderline={true}
                onChange={(e) => setSelectedBusinessStatus(e.target.value)}
                style={{
                  border: "1px solid rgb(148 148 148)",
                  width: "100px",
                  borderRadius: "4px",
                  padding: "3px",
                  marginBottom: "10px",
                }}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                {BusinessStatus.map((status, i) => {
                  return <option value={status}>{status}</option>;
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item>
              <FormControl>
                <NativeSelect
                  defaultValue={"email"}
                  disableUnderline={true}
                  onChange={(e) =>
                    setSelectedBusinessSearchCategory(e.target.value)
                  }
                  style={{
                    border: "1px solid rgb(148 148 148)",
                    width: "100px",
                    borderRadius: "4px",
                    padding: "3px",
                    marginBottom: "10px",
                  }}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  {BusinessSearch.map((status, i) => {
                    return (
                      <>
                        <option value={status}>{status}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <TextField
              lin
              InputProps={{ disableUnderline: true }}
              style={{
                marginTop: "1px",
                marginLeft: "3px",
                border: "1px solid rgb(148 148 148)",
                padding: "1px 1px 0px 7px",
                borderRadius: "3px",
                height: "35px",
              }}
              placeholder={`Search by ${SelectedBusinessSearchCategory}`}
              onChange={(e) =>
                setSelectedBusinessSearchTextInput(e.target.value)
              }
            />
            <Button
              variant="contained"
              style={{
                color: "rgb(255 255 255 / 87%)",
                height: "37px",
                width: "118px",
                marginLeft: "4px",
                backgroundColor: "rgb(115, 29, 216)",
              }}
              onClick={() => searchFilter()}
            >
              Search
            </Button>
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Button
              style={{
                marginBottom: "11px",
                backgroundColor: "rgb(115, 29, 216)",
                padding: "8px",
                color: "white",
              }}
              onClick={() => openCalendar()}
            >
              <FilterListOutlined />
              Filter by Date
            </Button>
          </Grid>
          <ShiftTable TableData={TableData} TableColumn={TableColumn} />
        </Grid>
      </Grid>
      <PaginationComponent
        limit={limit}
        setLimit={setLimit}
        pageNumber={PageNumber}
        setPageNumber={setPageNumber}
        totalRows={TotalRows}
        from="shiftTable"
      />
      <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
      {ShowCalander && (
        <CalendarModal
          ShowCalander={ShowCalander}
          setShowCalander={setShowCalander}
          StartDate={StartDate}
          EndDate={EndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(Shift);
