import { Box, Grid, makeStyles, Modal } from "@material-ui/core";
import { Close, MonetizationOn } from "@material-ui/icons";
import moment from "moment";
import React from "react";
import styled, { withTheme } from "styled-components/macro";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
    padding: "13px 25px 14px 22px",
    width: "36%",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 100%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;

function ViewBilling({
  OpenViewModal,
  setOpenViewModal,
  ViewModalData,
  SelectedBilling,
}) {
  const classes = useStyles();

  const removeFiledsValue = () => {
    setOpenViewModal(false);
  };
  console.log("SelectedBilling", SelectedBilling);
  return (
    <Grid>
      <Modal
        hideBackdrop
        open={OpenViewModal}
        onClose={() => setOpenViewModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              xs={12}
              onClick={() => removeFiledsValue()}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <Close style={{ fontSize: "27px" }} />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              //   alignItems: "cenetr",
            }}
          >
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p style={{ fontSize: "15px", fontWeight: "600" }}>
                  Business Name
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p style={{ fontSize: "15px" }}>
                  {SelectedBilling?.shift?.businessUser?.firstName
                    ? SelectedBilling?.shift?.businessUser?.firstName
                    : "" + " " + SelectedBilling?.shift?.businessUser?.lastName
                    ? SelectedBilling?.shift?.businessUser?.lastName
                    : ""}
                </p>
              </Grid>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <p style={{ fontSize: "15px", fontWeight: "600" }}>
                Job Description
              </p>
            </Grid>

            <img
              style={{ width: "100%", height: "175px", marginBottom: "10px" }}
              src={SelectedBilling?.shift?.thumbnail}
            />
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                <MonetizationOn
                  style={{ color: "#32E981", fontSize: "16px" }}
                />
                {""} {SelectedBilling?.totalHours}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                <MonetizationOn
                  style={{ color: "#32E981", fontSize: "16px" }}
                />
                {""} {""}{" "}
                {moment(SelectedBilling?.createdAt).format(
                  "DD-MM-YYYY hh:mm:ss"
                )}
              </p>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  marginBottom: "0px",
                }}
              >
                <MonetizationOn
                  style={{ color: "#32E981", fontSize: "16px" }}
                />
                {""} {""} Shoppinghut, Grambling, LA - 71245
              </p>
            </Grid>
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ marginTop: "10px" }}
            >
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  Job Amount
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  {SelectedBilling?.shiftAmount}
                </p>
              </Grid>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  Promo Code
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  {SelectedBilling?.promoCode ? SelectedBilling?.promoCode : 0}
                </p>
              </Grid>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  Tip
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  {SelectedBilling?.tip ? SelectedBilling?.tip : 0}
                </p>
              </Grid>
            </Grid>

            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  Application Fee
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "gray",
                    marginBottom: "0px",
                  }}
                >
                  {SelectedBilling?.application
                    ? SelectedBilling?.application
                    : 0}
                </p>
              </Grid>
            </Grid>
            <div
              style={{
                margin: "10px 0 0 0",
                border: "1px solid #d6d6d6",
                width: "100%",
              }}
            >
              {""}
            </div>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "black",
                    marginBottom: "0px",
                  }}
                >
                  Amount
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    color: "black",
                    fontWeight: "600",
                    marginBottom: "0px",
                  }}
                >
                  {SelectedBilling?.totalAmount
                    ? SelectedBilling?.totalAmount
                    : 0}
                </p>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(ViewBilling);
