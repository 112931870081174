import { Box, Button, Grid, makeStyles, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useHistory } from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";
import API, { get } from "../../../utils/api";

const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
    padding: "40px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 80%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;

function ApproveModal({ approveModal, setapproveModal, userId, type }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const approveShifter = async () => {
    try {
      const approvedUserRes = await get(`${API.approveUser}/${userId}`);
      if (approvedUserRes.statusCode === 202) {
        enqueueSnackbar(approvedUserRes.message, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        if (type === "shifter") {
          history.push("/dashboard/shifter");
        } else {
          history.push("/dashboard/business");
        }
      } else {
        enqueueSnackbar(approvedUserRes.message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }
      setapproveModal(false);
      removeFiledsValue();
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };

  const removeFiledsValue = () => {
    setapproveModal(false);
  };
  return (
    <Grid>
      <Modal
        hideBackdrop
        open={approveModal}
        onClose={() => setapproveModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid container>
            <Grid item container sm={12} md={12} lg={12} xs={12}>
              <Grid item sm={12} md={6} lg={6} xs={6}>
                <h2
                  id="child-modal-title"
                  style={{
                    color: "#333",
                    fontSize: "20px",
                  }}
                >
                  Approve User
                </h2>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                lg={6}
                xs={6}
                onClick={() => removeFiledsValue()}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  cursor: "pointer",
                }}
              >
                <Close style={{ fontSize: "27px" }} />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cenetr",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p style={{ fontSize: "15px" }}>
                Are you sure you want to approve this user?
              </p>
            </Grid>
            <Grid item container lg={12} md={12} sm={12}>
              <Grid item lg={6} md={6} sm={6} sx={6}>
                <Button
                  onClick={() => removeFiledsValue()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "gray",
                    border: "1px solid gray",
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={() => approveShifter()}
                  style={{
                    marginLeft: "5px",
                    color: "white",
                    backgroundColor: "green",
                    border: "1px solid green",
                  }}
                >
                  APPROVE
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(ApproveModal);
