import { Box, Grid, makeStyles, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React from "react";
import { useSelector } from "react-redux";
import styled, { withTheme } from "styled-components/macro";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(0,0,0,0.4)",
    borderRadius: "1px",
    padding: "40px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const ModalBody = styled(Box)`
    background-color: white,
    display: flex,
    flex-direction: column,
    justify-content: center,
    align-items: center,
    width: 80%,
    padding: 50px;
    outline: none;
  ${(props) => props.theme.breakpoints.down("sm")} {
    width:92%;
    max-height:80vh;
    overflow-y:scroll;
  }
`;
const ProfileImg = styled.img`
  padding: 0px 100px 0px;
  width: calc(90% - 80px);
  overflow: auto;
  margin: auto auto;
  display: flex;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: calc(100% - 80px);
    max-height: 80vh;
    overflow-y: scroll;
  }
`;

function UserProfileModal({
  ProfileModal,
  setProfileModal,
  showProfile,
  ProfileLink,
  UploadHeadshotStatus,
}) {
  const redux = useSelector((data) => {
    return data;
  });
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  return (
    <Grid>
      <Modal
        hideBackdrop
        open={ProfileModal}
        onClose={() => setProfileModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className={classes.modalStyle}
      >
        <ModalBody className={classes.contentStyle}>
          <Grid item container sm={12} md={12} lg={12} xs={12}>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <Close
                onClick={() => setProfileModal(false)}
                style={{ fontSize: "32px", color: "white" }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ProfileImg
                src={
                  UploadHeadshotStatus
                    ? URL.createObjectURL(showProfile)
                    : ProfileLink
                }
                onClick={() => setProfileModal(true)}
              />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </Grid>
  );
}

export default withTheme(UserProfileModal);
