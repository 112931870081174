import { Button, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import TableComponent from "../../components/Table";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import API, { get, post } from "../../../utils/api";
import { useSelector } from "react-redux";

import PositionTable from "./PositionTable";
import AddPositionModal from "./AddPositionModal";
import EditPositionModal from "./EditPositionModal";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import DeletePositionModal from "./DeletePositionModal";

function Position() {
  const history = useHistory();

  const redux = useSelector((data) => {
    return data;
  });
  const [TableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [PageNumber, setPageNumber] = useState(
    redux?.authReducer?.PositionTablePageNumber
  );
  const [TotalRows, setTotalRows] = useState(1);
  const [TableColumn, setTableColumn] = useState([
    "S.NO",
    "Name",
    "Status",
    "Edit",
    "Delete",
  ]);
  const [AddModal, setAddModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [Name, setName] = useState("");
  const [Status, setStatus] = useState("enable");
  const [PositionId, setPositionId] = useState("");
  useEffect(() => {
    getPosition();
  }, [PageNumber, limit]);

  const getPosition = async () => {
    try {
      const body = {
        pageNumber: PageNumber,
        limit: limit,
        status: "enable",
      };
      const getPositionRes = await post(API.Position, body);
      // console.log("getPositionRes", getPositionRes);
      setTableData(getPositionRes?.data);
      setTotalRows(getPositionRes?.count);
    } catch (error) {
      // console.log("error", error);
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <ArrowBack
        style={{ marginBottom: "20px", cursor: "pointer" }}
        onClick={() => history.push("/setting")}
      />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Position
          </Typography>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor: "rgb(115, 29, 216)",
              padding: "10px",
              color: "white",
              borderRadius: "5px",
            }}
            onClick={() => setAddModal(true)}
          >
            Add Position
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "31px" }}
        >
          <PositionTable
            EditModal={EditModal}
            setEditModal={setEditModal}
            DeleteModal={DeleteModal}
            setDeleteModal={setDeleteModal}
            TableData={TableData}
            TableColumn={TableColumn}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            PositionId={PositionId}
            setPositionId={setPositionId}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <PaginationComponent
            limit={limit}
            setLimit={setLimit}
            pageNumber={PageNumber}
            setPageNumber={setPageNumber}
            totalRows={TotalRows}
            from="positionTable"
          />
        </Grid>
        {AddModal && (
          <AddPositionModal
            AddModal={AddModal}
            setAddModal={setAddModal}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            getPosition={getPosition}
          />
        )}
        {EditModal && (
          <EditPositionModal
            EditModal={EditModal}
            setEditModal={setEditModal}
            Name={Name}
            setName={setName}
            Status={Status}
            setStatus={setStatus}
            getPosition={getPosition}
            PositionId={PositionId}
            setPositionId={setPositionId}
          />
        )}
        {DeleteModal && (
          <DeletePositionModal
            DeleteModal={DeleteModal}
            setDeleteModal={setDeleteModal}
            PositionId={PositionId}
            setStatus={setStatus}
            getPosition={getPosition}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(Position);
