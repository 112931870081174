import { Button, Grid, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import ApproveModal from "./ApproveModal";
import UserProfileModal from "./UserProfileModal";

function ShifterProfile({}) {
  const location = useLocation();
  const history = useHistory();
  const [approveModal, setapproveModal] = useState(false);
  const [businessData, setBusinessData] = useState(location.state);
  const [ProfileModal, setProfileModal] = useState(false);

  const downloadResumeLink = async (url) => {
    axios
      .get(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "resume.jpg";
        link.click();
      })
      .catch(console.error);
  };
  console.log("businessData", businessData);
  return (
    <React.Fragment>
      <Helmet title="Shifter | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() => history.push("/dashboard/shifter")}
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            Shifterr Profile
          </Typography>
        </Grid>
        {businessData.status === "pending" ? (
          <Grid item>
            <Button
              onClick={() => setapproveModal(true)}
              style={{ backgroundColor: "green", color: "white" }}
            >
              Approve Shifterr
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid
          item
          lg={4}
          md={4}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              onClick={() => setProfileModal(true)}
              src={
                businessData?.shifterDetail?.profileLink
                  ? businessData?.shifterDetail?.profileLink
                  : "https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg"
              }
              style={{
                width: "100px",
                height: "100px",
                display: "block",
                borderRadius: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "27px",
                cursor: "pointer",
              }}
            />
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                BASIC DETAILS
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Address
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {businessData?.address ? businessData?.address : "--"}
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Food Handler
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: businessData?.shifterDetail?.foodHandler
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{
                      backgroundColor: !businessData?.shifterDetail?.foodHandler
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    No
                  </Button>
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Type of work
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: businessData?.shifterDetail?.fullTime
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Full Time
                  </Button>
                  <Button
                    style={{
                      backgroundColor: businessData?.shifterDetail?.partTime
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Part Time
                  </Button>
                  <Button
                    style={{
                      backgroundColor: businessData?.shifterDetail?.temporary
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Temporary
                  </Button>
                </p>
              </p>
              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                Certified Us
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: businessData?.shifterDetail?.certifiedUs
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{
                      backgroundColor: !businessData?.shifterDetail?.certifiedUs
                        ? "rgb(115, 29, 216)"
                        : "gray",
                      borderRadius: "67px",
                      color: "white",
                      marginLeft: "5px",
                      marginTop: "4px",
                    }}
                  >
                    No
                  </Button>
                </p>
              </p>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                CONTACT DETAILS
              </p>

              <p
                style={{
                  color: "rgba(33, 33, 33, 1)",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "700",
                }}
              >
                Address
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {businessData?.address ? businessData?.address : "--"}
                </p>
              </p>

              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                  marginTop: "20px",
                }}
              >
                PRIMARY CONTACT
              </p>
              <Grid item container lg={12} md={12} sm={12} xs={12}>
                {businessData?.shifterDetail ? (
                  <>
                    {businessData?.shifterDetail?.isCallPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Call
                      </Button>
                    ) : null}
                    {businessData?.shifterDetail?.isEmailPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Email
                      </Button>
                    ) : null}
                    {businessData?.shifterDetail?.isTextPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Text
                      </Button>
                    ) : null}
                    {businessData?.shifterDetail?.isOtherPrimary ? (
                      <Button
                        style={{
                          backgroundColor: "rgb(115, 29, 216)",
                          borderRadius: "67px",
                          color: "white",
                          marginLeft: "5px",
                        }}
                      >
                        Other
                      </Button>
                    ) : null}
                  </>
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      fontFamily: "inter",
                    }}
                  >
                    Primary contact not found
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ padding: "19px", marginTop: "20px" }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  SHIFTERR DETAILS
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Name
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.firstName
                      ? businessData?.firstName + businessData?.lastName
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Email
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      window.location.href = `mailto:${businessData.email}`;
                      e.preventDefault();
                    }}
                  >
                    {businessData.email ? businessData.email : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    window.open(`tel:${businessData.phone}`);
                    e.preventDefault();
                  }}
                >
                  Phone Number
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData.phone ? businessData.phone : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Zipcode
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData.zipCode ? businessData.zipCode : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Are you vaccinated?
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.shifterDetail?.vaccinated
                      ? businessData?.shifterDetail?.vaccinated
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Venmo Username
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.paymentDetails?.venmoUsername
                      ? businessData?.paymentDetails?.venmoUsername
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Experience
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.shifterDetail?.experience
                      ? businessData?.shifterDetail?.experience
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Certificates
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {businessData?.shifterDetail?.certificates
                      ? businessData?.shifterDetail?.certificates
                      : "--"}
                  </p>
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
            }}
          >
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ padding: "19px" }}
            >
              <p
                style={{
                  color: "rgba(33, 33, 33, 0.7)",
                  fontFamily: "Inter",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                ATTACHED DOCUMENTS
              </p>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {businessData?.shifterDetail ? (
                  <Button
                    style={{
                      backgroundColor: "rgb(115, 29, 216)",
                      color: "white",
                      marginLeft: "5px",
                    }}
                  >
                    <a
                      href={businessData?.shifterDetail?.resumeLink}
                      style={{ textDecoration: "none", color: "white" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Resume
                    </a>
                  </Button>
                ) : (
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      fontFamily: "inter",
                    }}
                  >
                    Resume not found
                  </p>
                )}

                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginTop: "20px",
                  }}
                >
                  BOROUGHS
                </p>
                <Grid item container lg={12} md={12} sm={12} xs={12}>
                  {businessData?.userBorough?.length > 0 ? (
                    businessData?.userBorough?.map((userBoroughs, i) => {
                      return (
                        <Button
                          style={{
                            backgroundColor: "rgb(115, 29, 216)",
                            borderRadius: "67px",
                            color: "white",
                            marginLeft: "5px",
                            marginTop: "4px",
                          }}
                        >
                          {userBoroughs?.borough?.name}
                        </Button>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        fontFamily: "inter",
                      }}
                    >
                      Boroughs not found
                    </p>
                  )}
                </Grid>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginTop: "20px",
                  }}
                >
                  AVAILABLE POSITIONS
                </p>
                <Grid item container lg={12} md={12} sm={12} xs={12}>
                  {businessData?.shifterPosition.length > 0 ? (
                    businessData?.shifterPosition?.map((shifterPosition, i) => {
                      return (
                        <Button
                          style={{
                            backgroundColor: "rgb(115, 29, 216)",
                            borderRadius: "67px",
                            color: "white",
                            marginLeft: "5px",
                            marginTop: "4px",
                          }}
                        >
                          {shifterPosition.position.name}
                        </Button>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        fontFamily: "inter",
                      }}
                    >
                      Positions not found
                    </p>
                  )}
                </Grid>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginTop: "20px",
                  }}
                >
                  LANGUAGES
                </p>
                <Grid item container lg={12} md={12} sm={12} xs={12}>
                  {businessData?.userLanguage.length > 0 ? (
                    businessData?.userLanguage?.map((userLanguage, i) => {
                      return (
                        <Button
                          style={{
                            backgroundColor: "rgb(115, 29, 216)",
                            borderRadius: "67px",
                            color: "white",
                            marginLeft: "5px",
                            marginTop: "4px",
                          }}
                        >
                          {userLanguage.language.name}
                        </Button>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "300",
                        fontFamily: "inter",
                      }}
                    >
                      Language not found
                    </p>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ marginTop: "29px" }}
                >
                  <Button
                    onClick={() =>
                      history.push("/payment/view", { ...businessData })
                    }
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    View Transaction Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {approveModal && (
        <ApproveModal
          approveModal={approveModal}
          setapproveModal={setapproveModal}
          userId={businessData?.id}
          type={businessData?.type}
        />
      )}
      {ProfileModal && (
        <UserProfileModal
          ProfileModal={ProfileModal}
          setProfileModal={setProfileModal}
          showProfile={businessData?.shifterDetail?.profileLink}
          ProfileLink={businessData?.shifterDetail?.profileLink}
          UploadHeadshotStatus={false}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(ShifterProfile);
