import {
  Button,
  FormControl,
  Grid,
  NativeSelect,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components/macro";
import LoginAction from "../../../redux/actions/authActions";
import API, { get } from "../../../utils/api";
import PaginationComponent from "../../components/TablePagination";
import BillingTable from "./Table";
import View from "./View";

function Billing({}) {
  const dispatch = useDispatch();

  const redux = useSelector((data) => {
    return data;
  });
  const [TableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(5);
  const [BusinessStatus, setBusinessStatus] = useState([
    "All",
    "Pending",
    "Enable",
    "Disable",
    "Delete",
  ]);
  const [BusinessSearch, setBusinessSearch] = useState(["Name"]);
  const [SelectedBusinessStatus, setSelectedBusinessStatus] = useState("All");
  const [OpenViewModal, setOpenViewModal] = useState(false);
  const [ViewModalData, setViewModalData] = useState(false);
  const [
    SelectedBusinessSearchCategory,
    setSelectedBusinessSearchCategory,
  ] = useState("Name");
  const [
    SelectedBusinessSearchTextInput,
    setSelectedBusinessSearchTextInput,
  ] = useState("");
  const history = useHistory();

  const [PageNumber, setPageNumber] = useState(
    redux?.authReducer?.BillingTablePageNumber
  );
  const [TotalRows, setTotalRows] = useState(1);
  const [TotalEarning, setTotalEarning] = useState(0);
  const [SelectedBilling, setSelectedBilling] = useState("");
  const [TableColumn, setTableColumn] = useState([
    "S.NO",
    "Business Name",
    "Total Amount",
    "Shift Amount",
    "Total Amount",
    "View",
  ]);
  useEffect(() => {
    getBilling();
  }, [PageNumber, limit, SelectedBusinessStatus]);

  useEffect(() => {
    UpdatePageNumberOfShifterTable();
  }, [SelectedBusinessStatus]);

  const getBilling = async () => {
    try {
      //   const isEmail =
      //     SelectedBusinessSearchCategory === "Email" &&
      //     SelectedBusinessSearchTextInput
      //       ? `email=${SelectedBusinessSearchTextInput}&`
      //       : "";
      //   const isName1 =
      //     SelectedBusinessSearchCategory === "First Name" &&
      //     SelectedBusinessSearchTextInput
      //       ? `firstName=${SelectedBusinessSearchTextInput}&`
      //       : "";
      //   const isName2 =
      //     SelectedBusinessSearchCategory === "Last Name" &&
      //     SelectedBusinessSearchTextInput
      //       ? `lastName=${SelectedBusinessSearchTextInput}&`
      //       : "";
      //   console.log(
      //     "api--",
      //     `${
      //       API.getBilling
      //     }?${isEmail}${isName1}${isName2}limit=${limit}&pageNumber=${PageNumber}&status=${SelectedBusinessStatus.toLowerCase()}`
      //   );
      const getBillingRes = await get(`${API.getBilling}`);
      setTableData(
        getBillingRes?.transactionData?.length > 0
          ? getBillingRes?.transactionData
          : []
      );
      setTotalEarning(getBillingRes?.totalEarning);
      // setTotalRows(
      //   getBillingRes?.data?.length > 0 ? getBillingRes?.count : 0
      // );
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  console.log("totalEarning", TotalEarning);

  const searchFilter = () => {
    getBilling();
  };

  const UpdatePageNumberOfShifterTable = () => {
    // console.log("hello");
    dispatch(LoginAction.getBillingTablePageNumber(1));
    setPageNumber(1);
  };

  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" component={"b"} gutterBottom>
            Billing
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: "31px" }}
        >
          <Grid
            item
            container
            lg={4}
            md={4}
            sm={12}
            xs={12}
            style={{
              border: "1px solid #cfc9c9",
              borderRadius: "4px",
              padding: "17px 0 1px 11px",
              marginBottom: "14px",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p>Total Earnings</p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p style={{ color: "#FA2E69", fontWeight: "600" }}>
                {TotalEarning?.sum > 0 ? TotalEarning.sum : 0}
              </p>
            </Grid>
          </Grid>
          <p style={{ fontWeight: "600" }}>Billing Details</p>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item>
              <FormControl>
                <NativeSelect
                  defaultValue={"Email"}
                  disableUnderline={true}
                  onChange={(e) =>
                    setSelectedBusinessSearchCategory(e.target.value)
                  }
                  style={{
                    border: "1px solid rgb(148 148 148)",
                    width: "100px",
                    borderRadius: "4px",
                    padding: "3px",
                    marginBottom: "10px",
                  }}
                  inputProps={{
                    name: "age",
                    id: "uncontrolled-native",
                  }}
                >
                  {BusinessSearch.map((status, i) => {
                    return (
                      <>
                        <option value={status}>{status}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <TextField
              InputProps={{ disableUnderline: true }}
              style={{
                marginTop: "1px",
                marginLeft: "3px",
                border: "1px solid rgb(148 148 148)",
                padding: "1px 1px 0px 7px",
                borderRadius: "3px",
                height: "35px",
              }}
              placeholder={`Search by ${SelectedBusinessSearchCategory}`}
              onChange={(e) =>
                setSelectedBusinessSearchTextInput(e.target.value)
              }
            />
            <Button
              variant="contained"
              style={{
                color: "rgb(255 255 255 / 87%)",
                height: "37px",
                width: "118px",
                marginLeft: "4px",
                backgroundColor: "rgb(115, 29, 216)",
              }}
              onClick={() => searchFilter()}
            >
              Search
            </Button>
          </Grid>
          <BillingTable
            TableData={TableData}
            TableColumn={TableColumn}
            setViewModalData={setViewModalData}
            setOpenViewModal={setOpenViewModal}
            setSelectedBilling={setSelectedBilling}
          />
        </Grid>
        {/* <Grid item lg={12} md={12} sm={12} xs={12}>
          <PaginationComponent
            limit={limit}
            setLimit={setLimit}
            pageNumber={PageNumber}
            setPageNumber={setPageNumber}
            totalRows={TotalRows}
            from="BillingTable"
          />
        </Grid> */}
      </Grid>
      {OpenViewModal && (
        <View
          OpenViewModal={OpenViewModal}
          setOpenViewModal={setOpenViewModal}
          ViewModalData={ViewModalData}
          SelectedBilling={SelectedBilling}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(Billing);
