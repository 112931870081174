import {
  Button,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
  Divider as MuiDivider,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { withTheme } from "styled-components/macro";
import TableComponent from "../../components/Table";
import SearchComponent from "../../../components/Search";
import PaginationComponent from "../../components/TablePagination";
import Avatar from "../../../assets/person.png";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import API, { get } from "../../../utils/api";
import { spacing } from "@material-ui/system";

import { Rating } from "@mui/material";
import { ArrowBack, DeleteForever } from "@material-ui/icons";
import DeleteFeedbackModal from "./DeleteFeedbackModal";
import ApproveModal from "../Business/ApproveModal";
import ApproveShiftModal from "./ApproveShiftModal";
import moment from "moment";
import styled from "styled-components/macro";

const MainGrid = styled(Grid)`
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
  }
`;

const Divider = styled(MuiDivider)(spacing);
const useStyles = makeStyles((theme) => ({
  modalStyle: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
  contentStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    borderRadius: "1px",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

function ViewShift({}) {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [CheckStatus, setCheckStatus] = useState(false);
  const classes = useStyles();
  const [shiftDetails, setshiftDetails] = useState(location.state);
  const [OfferedApplicants, setOfferedApplicants] = useState([]);
  const [AppliedApplicants, setAppliedApplicants] = useState([]);
  const [HiredApplicants, setHiredApplicants] = useState([]);
  const [RejectApplicants, setRejectApplicants] = useState([]);

  const [shifterDetailById, setshifterDetailById] = useState();
  const [DeleteModal, setDeleteModal] = useState(false);
  const [FeedbackId, setFeedbackId] = useState("");
  const [FeedbackType, setFeedbackType] = useState("");
  const [approveModal, setapproveModal] = useState(false);
  const [
    getFeedbackFromBusinessToShifterData,
    setgetFeedbackFromBusinessToShifterData,
  ] = useState([]);

  // console.log("shifterDetailById----------", shifterDetailById);
  // console.log("iddd222--", shiftDetails?.businessUser?.id, shiftDetails?.id);

  const getOfferedApplicants = () => {};
  useEffect(() => {
    // setTimeout(() => {
    getOfferedApplicants();
    // }, 1000);
  }, []);
  useEffect(() => {
    getShiftDetail();
    getFeedbackFromBusinessToShifter();
    // if (CheckStatus) {

    // }
  }, []);

  const getShiftDetail = async () => {
    setLoading(true);
    try {
      const getShiftByIdRes = await get(
        `${API.getShiftDetails}/${shiftDetails?.id}`
      );
      setshifterDetailById(getShiftByIdRes);
      const getOfferedAppRes = getShiftByIdRes?.application?.filter(
        (shifterApplicantion, i) => shifterApplicantion.status === "offered"
      );
      const getAppliedAppRes = getShiftByIdRes?.application?.filter(
        (shifterApplicantion, i) => shifterApplicantion.status === "applied"
      );
      const getHiredAppRes = getShiftByIdRes?.application?.filter(
        (shifterApplicantion, i) =>
          shifterApplicantion.status === "hired" ||
          shifterApplicantion.status === "completed"
      );
      const getRejectAppRes = getShiftByIdRes?.application?.filter(
        (shifterApplicantion, i) => shifterApplicantion.status === "reject"
      );
      // console.log("getHiredAppRes", getShiftByIdRes);
      setOfferedApplicants(getOfferedAppRes);
      setAppliedApplicants(getAppliedAppRes);
      setHiredApplicants(getHiredAppRes);
      setRejectApplicants(getRejectAppRes);
      setLoading(false);
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
      if (error?.response?.status) {
        history.push("/auth/sign-in");
      }
    }
  };

  const getFeedbackFromBusinessToShifter = async () => {
    // console.log("iddd1111--", shiftDetails?.businessUser?.id, shiftDetails?.id);
    try {
      const getFeedbacksRes = await get(
        `${API.getFeedbackFromBusinessToShifter}/${shiftDetails?.businessUser?.id}/${shiftDetails?.id}`
      );
      // console.log("getFeedbacksRes", getFeedbacksRes);
      setgetFeedbackFromBusinessToShifterData(getFeedbacksRes);
      setLoading(false);
    } catch (error) {
      // console.log("error", error);
      setLoading(false);
      if (error?.response?.status === 401) {
        history.push("/auth/sign-in");
      }
    }
  };
  const openDeleteFeedbackModal = (row) => {
    setFeedbackType(row?.from);
    setDeleteModal(true);

    setFeedbackId(row.id);
  };
  return (
    <React.Fragment>
      <Helmet title="Shifterr | Dashboard" />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <ArrowBack
            style={{ marginRight: "15px", cursor: "pointer" }}
            onClick={() => history.push("/shift")}
          />
          <Typography variant="h3" component={"b"} gutterBottom>
            {shifterDetailById?.jobTitle ? shifterDetailById?.jobTitle : ""}
          </Typography>
        </Grid>
        {shifterDetailById?.status === "pending" ? (
          <Grid>
            <Grid item>
              <Button
                onClick={() => setapproveModal(true)}
                style={{ backgroundColor: "green", color: "white" }}
              >
                Approve Shift
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <img
              src={
                shifterDetailById?.thumbnail
                  ? shifterDetailById?.thumbnail
                  : "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
              }
              style={{
                width: "65px",
                height: "65px",
                borderRadius: "100px",
              }}
            />
            <p
              style={{
                color: "rgba(33, 33, 33, 0.7)",
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "15px",
                marginTop: "20px",
              }}
            >
              SHIFT DETAILS
            </p>
            <Divider mb={5} />
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Job Title
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.jobTitle
                      ? shifterDetailById?.jobTitle
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Skill
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.requiredSkill
                      ? shifterDetailById?.requiredSkill
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Description
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.description
                      ? shifterDetailById?.description
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Pay Per Hour
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.payPerHour
                      ? shifterDetailById?.payPerHour
                      : "--"}
                  </p>
                </p>

                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Desired Experience Level
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.desiredExperienceLevel
                      ? shifterDetailById?.desiredExperienceLevel
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Duration
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.duration
                      ? shifterDetailById?.duration
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Special Event
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: shifterDetailById?.specialEvent
                          ? "rgb(115, 29, 216)"
                          : "gray",
                        borderRadius: "67px",
                        color: "white",
                        marginLeft: "5px",
                        marginTop: "4px",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        backgroundColor: !shifterDetailById?.specialEvent
                          ? "rgb(115, 29, 216)"
                          : "gray",
                        borderRadius: "67px",
                        color: "white",
                        marginLeft: "5px",
                        marginTop: "4px",
                      }}
                    >
                      No
                    </Button>
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Tipped Position
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: shifterDetailById?.tippedPosition
                          ? "rgb(115, 29, 216)"
                          : "gray",
                        borderRadius: "67px",
                        color: "white",
                        marginLeft: "5px",
                        marginTop: "4px",
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        backgroundColor: !shifterDetailById?.tippedPosition
                          ? "rgb(115, 29, 216)"
                          : "gray",
                        borderRadius: "67px",
                        color: "white",
                        marginLeft: "5px",
                        marginTop: "4px",
                      }}
                    >
                      No
                    </Button>
                  </p>
                </p>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Start Time
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.startTime
                      ? moment(shifterDetailById?.startTime).format(
                          "hh:mm:ss A"
                        )
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  End Time
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.endTime
                      ? moment(shifterDetailById?.endTime).format("hh:mm:ss A")
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Date
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.date ? shifterDetailById?.date : "--"}
                  </p>
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              border: "1px solid rgba(207, 207, 207, 1)",
              borderRadius: "15px",
              marginTop: "30px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <MainGrid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ padding: "19px", marginTop: "20px" }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  BUSINESS DETAILS
                </p>
                <Divider mb={5} />
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Name
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.firstName
                      ? shifterDetailById?.businessUser?.firstName +
                        shifterDetailById?.businessUser?.lastName
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Email
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.email
                      ? shifterDetailById?.businessUser?.email
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Phone Number
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.phone
                      ? shifterDetailById?.businessUser?.phone
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Zipcode
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.zipCode
                      ? shifterDetailById?.businessUser?.zipCode
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Address
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.address
                      ? shifterDetailById?.businessUser?.address
                      : "--"}
                  </p>
                </p>
                <p
                  style={{
                    color: "rgba(33, 33, 33, 1)",
                    fontFamily: "Inter",
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  Status
                  <p
                    style={{
                      color: "rgba(33, 33, 33, 1)",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    {shifterDetailById?.businessUser?.status
                      ? shifterDetailById?.businessUser?.status[0].toUpperCase() +
                        shifterDetailById?.businessUser?.status.slice(1)
                      : "--"}
                  </p>
                </p>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                style={{ padding: "19px", marginTop: "20px" }}
              >
                <p
                  style={{
                    color: "rgba(33, 33, 33, 0.7)",
                    fontFamily: "Inter",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  POSITIONS
                </p>
                <Divider mb={5} />
                <Button
                  style={{
                    backgroundColor: "rgb(115, 29, 216)",
                    borderRadius: "67px",
                    color: "white",
                  }}
                >
                  {shifterDetailById?.position?.name
                    ? shifterDetailById?.position?.name
                    : "--"}
                </Button>
              </Grid>
            </MainGrid>
          </Grid>
        </Grid>

        {shifterDetailById?.status === "complete" ||
        shifterDetailById?.status === "hired" ? (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "24px 0px 0 5px",
            }}
          >
            <p
              style={{
                color: "rgba(33, 33, 33, 0.7)",
                fontFamily: "Inter",
                fontWeight: "800",
                fontSize: "21px",
              }}
            >
              HIRED APPLICANTS DETAILS
            </p>
          </Grid>
        ) : null}
        {shifterDetailById?.status === "complete" ||
        shifterDetailById?.status === "hired" ? (
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              gap: 3,
              flexDirection: "row",
            }}
          >
            {HiredApplicants?.length > 0 ? (
              HiredApplicants?.map((shifterApplicantsDetails, i) => {
                return (
                  <Grid
                    item
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "19px",
                      border: "1px solid rgba(207, 207, 207, 1)",
                      borderRadius: "15px",
                    }}
                  >
                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <img
                          src={
                            "https://mandksolicitors.com/wp-content/uploads/2022/01/istockphoto-666545204-612x612-1.jpg"
                          }
                          style={{
                            width: "50px",
                            height: "50px",
                            display: "block",
                            borderRadius: "100px",
                            marginBottom: "20px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: "10px" }}
                      >
                        <Divider />
                      </Grid>

                      <Grid item container lg={12} md={12} sm={12} xs={12}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 0.7)",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            BASIC DETAILS
                          </p>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Name
                            <p
                              style={{
                                color: "rgba(33, 33, 33, 1)",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {shifterApplicantsDetails.shifterUser.firstName
                                ? shifterApplicantsDetails.shifterUser
                                    .firstName +
                                  shifterApplicantsDetails.shifterUser.lastName
                                : "--"}
                            </p>
                          </p>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Email
                            <p
                              style={{
                                color: "rgba(33, 33, 33, 1)",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {shifterApplicantsDetails.shifterUser.email
                                ? shifterApplicantsDetails.shifterUser.email
                                : "--"}
                            </p>
                          </p>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "13px",
                              fontWeight: "500",
                            }}
                          >
                            Phone Number
                            <p
                              style={{
                                color: "rgba(33, 33, 33, 1)",
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {shifterApplicantsDetails.shifterUser.phone
                                ? shifterApplicantsDetails.shifterUser.phone
                                : "--"}
                            </p>
                          </p>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 0.7)",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "15px",
                              marginTop: "20px",
                            }}
                          >
                            PRIMARY CONTACT
                          </p>
                          <Grid
                            item
                            spacing={3}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                          >
                            {shifterApplicantsDetails?.shifterUser
                              ?.shifterDetail.isCallPrimary ? (
                              <Button
                                style={{
                                  backgroundColor: "rgb(115, 29, 216)",
                                  borderRadius: "67px",
                                  color: "white",
                                  marginLeft: "2px",
                                }}
                              >
                                Call
                              </Button>
                            ) : null}
                            {shifterApplicantsDetails?.shifterUser
                              ?.shifterDetail?.isEmailPrimary ? (
                              <Button
                                style={{
                                  backgroundColor: "rgb(115, 29, 216)",
                                  borderRadius: "67px",
                                  color: "white",
                                  marginLeft: "2px",
                                }}
                              >
                                Email
                              </Button>
                            ) : null}
                            {shifterApplicantsDetails?.shifterUser
                              ?.shifterDetail?.isTextPrimary ? (
                              <Button
                                style={{
                                  backgroundColor: "rgb(115, 29, 216)",
                                  borderRadius: "67px",
                                  color: "white",
                                  marginLeft: "2px",
                                }}
                              >
                                Text
                              </Button>
                            ) : null}
                            {shifterApplicantsDetails?.shifterUser
                              ?.shifterDetail.isOtherPrimary ? (
                              <Button
                                style={{
                                  backgroundColor: "rgb(115, 29, 216)",
                                  borderRadius: "67px",
                                  color: "white",
                                  marginLeft: "2px",
                                }}
                              >
                                Other
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 0.7)",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "15px",
                            }}
                          >
                            AVAILABLE POSITIONS
                          </p>
                          <Grid
                            item
                            container
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ marginTop: "10px" }}
                          >
                            {shifterApplicantsDetails?.shifterUser
                              ?.shifterPosition?.length > 0 ? (
                              shifterApplicantsDetails?.shifterUser?.shifterPosition?.map(
                                (shifterPosition, i) => {
                                  if (shifterPosition.position.name) {
                                    return (
                                      <Button
                                        style={{
                                          backgroundColor: "rgb(115, 29, 216)",
                                          borderRadius: "67px",
                                          color: "white",
                                          marginLeft: "5px",
                                          marginTop: "3px",
                                        }}
                                      >
                                        {shifterPosition.position.name}
                                      </Button>
                                    );
                                  } else return null;
                                }
                              )
                            ) : (
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 300,
                                  fontFamily: "Inter",
                                }}
                              >
                                Shifterr Position not found
                              </p>
                            )}
                          </Grid>
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 0.7)",
                              fontFamily: "Inter",
                              fontWeight: "500",
                              fontSize: "15px",
                              marginTop: "20px",
                            }}
                          >
                            BOROUGHS
                          </p>
                          <Grid item container lg={12} md={12} sm={12} xs={12}>
                            {shifterApplicantsDetails?.shifterUser?.userBorough?.map(
                              (userBoroughs, i) => {
                                if (userBoroughs?.borough?.name) {
                                  return (
                                    <Button
                                      style={{
                                        backgroundColor: "rgb(115, 29, 216)",
                                        borderRadius: "67px",
                                        color: "white",
                                        marginLeft: "5px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {userBoroughs?.borough?.name}
                                    </Button>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <p> Hired Applicants not found</p>
            )}
          </Grid>
        ) : null}

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              color: "rgba(33, 33, 33, 0.7)",
              fontFamily: "Inter",
              fontWeight: "800",
              fontSize: "21px",
            }}
          >
            APPLICANTS DETAILS
          </p>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{
            display: "flex",

            flexDirection: "row",
          }}
        >
          {AppliedApplicants?.length > 0 ? (
            AppliedApplicants?.map((shifterApplicantsDetails, i) => {
              return (
                <Grid
                  item
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px 15px 0 17px",
                    border: "1px solid rgba(207, 207, 207, 1)",
                    borderRadius: "15px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={
                          "https://mandksolicitors.com/wp-content/uploads/2022/01/istockphoto-666545204-612x612-1.jpg"
                        }
                        style={{
                          width: "50px",
                          height: "50px",

                          borderRadius: "100px",
                          marginBottom: "20px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      lg={10}
                      md={10}
                      sm={12}
                      xs={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                        >
                          BASIC DETAILS
                        </p>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                        >
                          Email
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "10px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.email
                              ? shifterApplicantsDetails.shifterUser.email
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                            marginLeft: "7px",
                          }}
                        >
                          Name
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "10px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.firstName
                              ? shifterApplicantsDetails.shifterUser.firstName +
                                shifterApplicantsDetails.shifterUser.lastName
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                            marginLeft: "7px",
                          }}
                        >
                          Phone
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "10px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.phone
                              ? shifterApplicantsDetails.shifterUser.phone
                              : "--"}
                          </p>
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <p> Applicants not found</p>
          )}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              color: "rgba(33, 33, 33, 0.7)",
              fontFamily: "Inter",
              fontWeight: "800",
              fontSize: "21px",
            }}
          >
            OFFERED DETAILS
          </p>
        </Grid>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            gap: 3,
            flexDirection: "row",
          }}
        >
          {OfferedApplicants?.length > 0 ? (
            OfferedApplicants?.map((shifterApplicantsDetails, i) => {
              return (
                <Grid
                  item
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "19px",
                    border: "1px solid rgba(207, 207, 207, 1)",
                    borderRadius: "15px",
                  }}
                >
                  <Grid item container lg={12} md={12} sm={12} xs={12}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <img
                        src={
                          "https://mandksolicitors.com/wp-content/uploads/2022/01/istockphoto-666545204-612x612-1.jpg"
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          display: "block",
                          borderRadius: "100px",
                          marginBottom: "20px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: "10px" }}
                    >
                      <Divider />
                    </Grid>

                    <Grid item container lg={12} md={12} sm={12} xs={12}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          BASIC DETAILS
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Name
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.firstName
                              ? shifterApplicantsDetails.shifterUser.firstName +
                                shifterApplicantsDetails.shifterUser.lastName
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Email
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.email
                              ? shifterApplicantsDetails.shifterUser.email
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Phone Number
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.phone
                              ? shifterApplicantsDetails.shifterUser.phone
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          PRIMARY CONTACT
                        </p>
                        <Grid item spacing={3} lg={12} md={12} sm={12} xs={12}>
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            .isCallPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Call
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            ?.isEmailPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Email
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            ?.isTextPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Text
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            .isOtherPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Other
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        //   style={{
                        //     padding: "19px",
                        //     border: "1px solid rgba(207, 207, 207, 1)",
                        //     borderRadius: "15px",
                        //     marginTop: "30px",
                        //     //   marginLeft: "3px",
                        //     // backgroundColor: "red",
                        //   }}
                      >
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          AVAILABLE POSITIONS
                        </p>
                        <Grid
                          item
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "10px" }}
                        >
                          {shifterApplicantsDetails?.shifterUser
                            ?.shifterPosition?.length > 0 ? (
                            shifterApplicantsDetails?.shifterUser?.shifterPosition?.map(
                              (shifterPosition, i) => {
                                if (shifterPosition.position.name) {
                                  return (
                                    <Button
                                      style={{
                                        backgroundColor: "rgb(115, 29, 216)",
                                        borderRadius: "67px",
                                        color: "white",
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {shifterPosition.position.name}
                                    </Button>
                                  );
                                } else return null;
                              }
                            )
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                fontWeight: 300,
                                fontFamily: "Inter",
                              }}
                            >
                              Shifterr Position not found
                            </p>
                          )}
                        </Grid>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          BOROUGHS
                        </p>
                        <Grid
                          item
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          // style={{ marginTop: "10px" }}
                        >
                          {shifterApplicantsDetails?.shifterUser?.userBorough?.map(
                            (userBoroughs, i) => {
                              if (userBoroughs?.borough?.name) {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor: "rgb(115, 29, 216)",
                                      borderRadius: "67px",
                                      color: "white",
                                      marginLeft: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {userBoroughs?.borough?.name}
                                  </Button>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <p> Offered details not found</p>
          )}
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "20px",
            // margin: "24px 0px 0 5px",
            // backgroundColor: "green",
          }}
        >
          <p
            style={{
              color: "rgba(33, 33, 33, 0.7)",
              fontFamily: "Inter",
              fontWeight: "800",
              fontSize: "21px",
            }}
          >
            REJECT DETAILS
          </p>
        </Grid>
        <Grid
          item
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            gap: 3,
            flexDirection: "row",
          }}
        >
          {RejectApplicants?.length > 0 ? (
            RejectApplicants?.map((shifterApplicantsDetails, i) => {
              return (
                <Grid
                  item
                  lg={5}
                  md={5}
                  sm={12}
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "19px",
                    border: "1px solid rgba(207, 207, 207, 1)",
                    borderRadius: "15px",
                    // marginTop: "30px",
                    // marginLeft: i % 2 === 0 ? "0px" : "3px",
                    // backgroundColor: "red",
                  }}
                >
                  <Grid
                    item
                    container
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    // style={{ backgroundColor: "pink" }}
                  >
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      // style={{ backgroundColor: "blue" }}
                    >
                      <img
                        src={
                          "https://mandksolicitors.com/wp-content/uploads/2022/01/istockphoto-666545204-612x612-1.jpg"
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          display: "block",
                          borderRadius: "100px",
                          marginBottom: "20px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: "10px" }}
                    >
                      <Divider />
                    </Grid>

                    <Grid
                      item
                      container
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      // style={{ backgroundColor: "red" }}
                    >
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          BASIC DETAILS
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Name
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.firstName
                              ? shifterApplicantsDetails.shifterUser.firstName +
                                shifterApplicantsDetails.shifterUser.lastName
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Email
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.email
                              ? shifterApplicantsDetails.shifterUser.email
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 1)",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontWeight: "500",
                          }}
                        >
                          Phone Number
                          <p
                            style={{
                              color: "rgba(33, 33, 33, 1)",
                              fontFamily: "Inter",
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {shifterApplicantsDetails.shifterUser.phone
                              ? shifterApplicantsDetails.shifterUser.phone
                              : "--"}
                          </p>
                        </p>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          PRIMARY CONTACT
                        </p>
                        <Grid
                          item
                          spacing={3}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          // style={{ marginTop: "10px" }}
                        >
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            .isCallPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Call
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            ?.isEmailPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Email
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            ?.isTextPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Text
                            </Button>
                          ) : null}
                          {shifterApplicantsDetails?.shifterUser?.shifterDetail
                            .isOtherPrimary ? (
                            <Button
                              style={{
                                backgroundColor: "rgb(115, 29, 216)",
                                borderRadius: "67px",
                                color: "white",
                                marginLeft: "2px",
                              }}
                            >
                              Other
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        //   style={{
                        //     padding: "19px",
                        //     border: "1px solid rgba(207, 207, 207, 1)",
                        //     borderRadius: "15px",
                        //     marginTop: "30px",
                        //     //   marginLeft: "3px",
                        //     // backgroundColor: "red",
                        //   }}
                      >
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          AVAILABLE POSITIONS
                        </p>
                        <Grid
                          item
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "10px" }}
                        >
                          {shifterApplicantsDetails?.shifterUser
                            ?.shifterPosition?.length > 0 ? (
                            shifterApplicantsDetails?.shifterUser?.shifterPosition?.map(
                              (shifterPosition, i) => {
                                if (shifterPosition.position.name) {
                                  return (
                                    <Button
                                      style={{
                                        backgroundColor: "rgb(115, 29, 216)",
                                        borderRadius: "67px",
                                        color: "white",
                                        marginLeft: "5px",
                                        marginTop: "3px",
                                      }}
                                    >
                                      {shifterPosition.position.name}
                                    </Button>
                                  );
                                } else return null;
                              }
                            )
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                fontWeight: 300,
                                fontFamily: "Inter",
                              }}
                            >
                              Shifterr Position not found
                            </p>
                          )}
                        </Grid>
                        <p
                          style={{
                            color: "rgba(33, 33, 33, 0.7)",
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          BOROUGHS
                        </p>
                        <Grid
                          item
                          container
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          // style={{ marginTop: "10px" }}
                        >
                          {shifterApplicantsDetails?.shifterUser?.userBorough?.map(
                            (userBoroughs, i) => {
                              if (userBoroughs?.borough?.name) {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor: "rgb(115, 29, 216)",
                                      borderRadius: "67px",
                                      color: "white",
                                      marginLeft: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    {userBoroughs?.borough?.name}
                                  </Button>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
          ) : (
            <p> Reject Details not found</p>
          )}
        </Grid>

        {shifterDetailById?.status === "complete" ||
        shifterDetailById?.status === "hired" ? (
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            spacing={5}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              spacing={5}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                Feedback from business to shifter
              </p>
            </Grid>
            <Grid item container lg={12} md={12} sm={12} xs={12}>
              {getFeedbackFromBusinessToShifterData?.length > 0 ? (
                getFeedbackFromBusinessToShifterData?.map((feedback, i) => {
                  if (feedback.from === "business") {
                    // console.log("feedback", feedback);
                    return (
                      <Grid
                        item
                        container
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid gainsboro",
                          marginBottom: "6px",
                          borderRadius: "4px",
                        }}
                      >
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={12}
                          xs={12}
                          style={{
                            display: "flex",
                            alignContent: "flex-start",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              feedback?.shifterUser?.shifterDetail?.profileLink
                                ? feedback?.shifterUser?.shifterDetail
                                    ?.profileLink
                                : "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
                            }
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "30px",
                              margin: "10px",
                            }}
                          />
                        </Grid>

                        <Grid item lg={5} md={5} sm={12} xs={12}>
                          <p
                            style={{
                              fontStyle: "italic",
                              margin: "7px",
                            }}
                          >
                            "{feedback?.comments}"
                          </p>
                          <Rating
                            name="read-only"
                            value={feedback?.stars}
                            readOnly
                          />
                        </Grid>

                        <Grid
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "flex-end",
                            justifyContent: "space-around",
                            alignItems: "flex-end",
                          }}
                        >
                          <DeleteForever
                            onClick={() => openDeleteFeedbackModal(feedback)}
                            style={{ color: "#dc3545", cursor: "pointer" }}
                          />
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p>Feedback not found</p>
              )}
            </Grid>
          </Grid>
        ) : null}

        {shifterDetailById?.status === "complete" ||
        shifterDetailById?.status === "hired" ? (
          <Grid
            item
            container
            lg={12}
            md={12}
            sm={12}
            spacing={5}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              spacing={5}
              xs={12}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                Feedback from shifter to business
              </p>
            </Grid>
            <Grid
              item
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // style={{ marginTop: "10px" }}
            >
              {getFeedbackFromBusinessToShifterData?.length > 0 ? (
                getFeedbackFromBusinessToShifterData?.map((feedback, i) => {
                  if (feedback.from === "shifter") {
                    // console.log("feedback", feedback);
                    return (
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "1px solid gainsboro",
                          marginBottom: "6px",
                          borderRadius: "4px",
                        }}
                      >
                        <Grid
                          item
                          lg={2}
                          md={2}
                          sm={12}
                          xs={12}
                          style={{
                            display: "flex",
                            alignContent: "flex-start",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={
                              feedback?.shifterUser?.shifterDetail?.profileLink
                                ? feedback?.shifterUser?.shifterDetail
                                    ?.profileLink
                                : "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
                            }
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "30px",
                              margin: "10px",
                            }}
                          />
                        </Grid>

                        <Grid item lg={5} md={5} sm={12} xs={12}>
                          <p
                            style={{
                              fontStyle: "italic",
                              margin: "7px",
                            }}
                          >
                            "{feedback?.comments}"
                          </p>
                          <Rating
                            name="read-only"
                            value={feedback?.stars}
                            readOnly
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "flex-end",
                            justifyContent: "space-around",
                            alignItems: "flex-end",
                          }}
                        >
                          <DeleteForever
                            onClick={() => openDeleteFeedbackModal(feedback)}
                            style={{ color: "#dc3545", cursor: "pointer" }}
                          />
                        </Grid>
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p>Feedback not found</p>
              )}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {DeleteModal && (
        <DeleteFeedbackModal
          FeedbackId={FeedbackId}
          setFeedbackId={setFeedbackId}
          DeleteModal={DeleteModal}
          setDeleteModal={setDeleteModal}
          getFeedbackFromBusinessToShifter={getFeedbackFromBusinessToShifter}
          FeedbackType={FeedbackType}
          // PositionId={PositionId}
          // getPosition={getPosition}
        />
      )}
      {approveModal && (
        <ApproveShiftModal
          approveModal={approveModal}
          setapproveModal={setapproveModal}
          shiftId={shifterDetailById?.id}
        />
      )}
    </React.Fragment>
  );
}

export default withTheme(ViewShift);
