import {
  TableBody,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";

import React from "react";
import { Eye } from "react-feather";

import { useHistory } from "react-router-dom";

function TableComponent({
  TableData,
  TableColumn,
  approveModal,
  setapproveModal,
}) {
  const history = useHistory();

  // console.log("TableData----", TableData);
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#731DD8" }}>
            <TableRow>
              {TableColumn.map((data) => {
                return (
                  <TableCell style={{ color: "white" }} align="center">
                    {data}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {TableData.map((row, i) => {
              // console.log("row", row);
              if (row.type === "business") {
                return (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row?.id}
                    </TableCell>
                    <TableCell align="center">
                      {row?.firstName ? `${row?.firstName}` : "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.lastName ? `${row?.lastName}` : "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.type === "business" ? "Business" : "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.email ? row?.email : "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.phone ? row?.phone : "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row?.status === "enable" ? (
                        <Button
                          style={{ backgroundColor: "#D8921F", color: "white" }}
                        >
                          Enable
                        </Button>
                      ) : row?.status === "pending" ? (
                        <Button
                          style={{
                            backgroundColor: "#F1F14C",
                            color: "white",
                          }}
                        >
                          Pending
                        </Button>
                      ) : row?.status === "disable" ? (
                        <Button
                          style={{
                            backgroundColor: "gray",
                            color: "white",
                          }}
                        >
                          Disable
                        </Button>
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#D22F27",
                            color: "white",
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() =>
                        row.type === "business"
                          ? history.push("/dashboard/business/edit", { ...row })
                          : history.push("/shifter/profile", { ...row })
                      }
                    >
                      <Edit style={{ cursor: "pointer" }} />
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() =>
                        row.type === "business"
                          ? history.push("/business/profile", { ...row })
                          : history.push("/shifter/profile", { ...row })
                      }
                    >
                      <Eye style={{ cursor: "pointer" }} />
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
        {TableData.length > 0 ? null : (
          <Grid container justify="center">
            <p style={{ textAlign: "center", margin: "10px", color: "#333" }}>
              Data not found
            </p>
          </Grid>
        )}
      </TableContainer>
    </React.Fragment>
  );
}

export default TableComponent;
