import {
  BusinessCenter,
  BusinessOutlined,
  CreditCard,
  LocalAtm,
  Payment,
  Settings,
} from "@material-ui/icons";
import React from "react";
import { Layout, S, CreditCardliders, Users, User } from "react-feather";
import async from "../components/Async";
import Boroughs from "../pages/dashboards/Boroughs";
import Profile from "../pages/dashboards/Business/BusinessProfile";
import ShifterProfile from "../pages/dashboards/Business/ShifterProfile";
import Position from "../pages/dashboards/Position";
import Setting from "../pages/dashboards/Setting";
import Shift from "../pages/dashboards/Shift";
import ViewShift from "../pages/dashboards/Shift/ViewShift";
import VenueType from "../pages/dashboards/VenueType";
import Shifter from "../pages/dashboards/Business/Shifter";
import EditBusiness from "../pages/dashboards/Business/EditBusiness";
import EditShifter from "../pages/dashboards/Business/EditShifter";
import CuisineType from "../pages/dashboards/CuisineType";
import PaymentDetails from "../pages/dashboards/PaymentDetails";
import ViewPaymentDetails from "../pages/dashboards/PaymentDetails/ViewPaymentDetails";
import Billing from "../pages/dashboards/Billing";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Business = async(() => import("../pages/dashboards/Business"));
const SaaS = async(() => import("../pages/dashboards/SaaS"));

// Protected routes
const ProtectedPage = async(() => import("../pages/protected/ProtectedPage"));

const dashboardsRoutes = {
  id: "Users",
  path: "/dashboard",
  name: "Users",

  // header: "Pages",
  icon: <User />,
  guard: AuthGuard,
  // containsHome: true,
  // children: null,
  children: [
    {
      path: "/dashboard/business",
      name: "Business",
      component: Business,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/shifter",
      name: "Shifterr",
      component: Shifter,
      guard: AuthGuard,
    },
  ],
  component: null,
};
const shiftRoutes = {
  id: "Shift",
  path: "/shift",
  name: "Shift",
  component: Shift,
  // header: "Pages",
  icon: <BusinessOutlined />,
  guard: AuthGuard,
  // containsHome: true,
  children: null,
};
// const paymentRoutes = {
//   id: "Payment",
//   path: "/payment",
//   name: "Payment",
//   component: PaymentDetails,
//   // header: "Pages",
//   icon: <LocalAtm />,
//   guard: AuthGuard,
//   // containsHome: true,
//   children: null,
// };
const billingRoutes = {
  id: "Billing",
  path: "/billing",
  name: "Billing",
  component: Billing,
  // header: "Pages",
  icon: <CreditCard />,
  guard: AuthGuard,
  // containsHome: true,
  children: null,
};

const settingsRoutes = {
  id: "Setting",
  path: "/setting",
  name: "Setting",
  component: Setting,
  // header: "Pages",
  icon: <Settings />,
  guard: AuthGuard,
  // containsHome: true,
  children: null,
};

const pagesRoutes = {
  id: "Pages",
  path: "/pages",
  icon: <Layout />,
  children: [
    {
      path: "/dashboard/saas",
      name: "SaaS",
      component: SaaS,
      guard: AuthGuard,
    },
    {
      path: "/business/profile",
      name: "Profile",
      component: Profile,
      guard: AuthGuard,
    },
    {
      path: "/shifter/profile",
      name: "Profile",
      component: ShifterProfile,
      guard: AuthGuard,
    },
    {
      path: "/setting/borough",
      name: "Boroughs",
      component: Boroughs,
      guard: AuthGuard,
    },
    {
      path: "/setting/position",
      name: "Position",
      component: Position,
      guard: AuthGuard,
    },
    {
      path: "/setting/cuisineType",
      name: "CuisineType",
      component: CuisineType,
      guard: AuthGuard,
    },
    {
      path: "/setting/venueType",
      name: "VenueType",
      component: VenueType,
      guard: AuthGuard,
    },
    {
      path: "/shift/view",
      name: "VenueType",
      component: ViewShift,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/business/edit",
      name: "Edit Business",
      component: EditBusiness,
      guard: AuthGuard,
    },
    {
      path: "/dashboard/shifter/edit",
      name: "Edit Shifterr",
      component: EditShifter,
      guard: AuthGuard,
    },
    {
      path: "/payment/view",
      // name: "Edit Shifterr",
      component: ViewPaymentDetails,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  settingsRoutes,
  pagesRoutes,
  shiftRoutes,
  // paymentRoutes,
  billingRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
// export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  settingsRoutes,
  shiftRoutes,
  // paymentRoutes,
  billingRoutes,
];
