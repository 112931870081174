import {
  TableBody,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import moment from "moment";

import React from "react";
import { Eye } from "react-feather";

import { useHistory } from "react-router-dom";

function ShiftTable({ TableData, TableColumn }) {
  const history = useHistory();
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#731DD8" }}>
            <TableRow>
              {TableColumn.map((data) => {
                return (
                  <TableCell style={{ color: "white" }} align="center">
                    {data}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {TableData.map((row, i) => {
              // console.log("row", row);
              return (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    {row?.id ? row.id : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.jobTitle ? row?.jobTitle : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.date ? row?.date : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.payPerHour ? row?.payPerHour : "--"}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      style={{
                        backgroundColor:
                          row.status === "hired"
                            ? "#4F92A7"
                            : row.status === "open"
                            ? "orange"
                            : row.status === "complete"
                            ? "#28a745"
                            : row.status === "ongoing"
                            ? "yellow"
                            : row.status === "pending"
                            ? "#F1F14C"
                            : row.status === "delete"
                            ? "#dc3545"
                            : "#cdb37b",
                        color: "white",
                        width: "100%",
                      }}
                    >
                      {row.status === "hired"
                        ? "Hired"
                        : row.status === "open"
                        ? "Open"
                        : row.status === "complete"
                        ? "Complete"
                        : row.status === "ongoing"
                        ? "Ongoing"
                        : row.status === "pending"
                        ? "Pending"
                        : row.status === "cancel"
                        ? "Cancel"
                        : "Delete"}
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {row?.startTime
                      ? moment(row?.startTime).format("hh:mm:ss")
                      : "--"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.endTime
                      ? moment(row?.endTime).format("hh:mm:ss")
                      : "--"}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.push("/shift/view", { ...row })}
                  >
                    <Eye />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {TableData.length > 0 ? null : (
          <Grid container justify="center">
            <p style={{ textAlign: "center", margin: "10px", color: "#333" }}>
              Data not found
            </p>
          </Grid>
        )}
      </TableContainer>
    </React.Fragment>
  );
}

export default ShiftTable;
